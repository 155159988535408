import React, { Component } from "react";
import HomeDisplay from "./home/HomeDisplay";

function ContentDisplay(props) {
  const get7ShiftsIntegration = (storeNames, store_id) => {
    try {
      return storeNames.filter((v, i) => v.id === store_id)[0][
        "scheduling_app_integration_7shifts"
      ];
    } catch (error) {
      return false;
    }
  };

  const getBusinessWeekStartDayIndex = (storeNames, store_id) => {
    const day_of_week_names = [
      "sunday",
      "monday",
      "tuesday",
      "wednesday",
      "thursday",
      "friday",
      "saturday",
    ];
    try {
      return day_of_week_names.indexOf(
        storeNames.filter((v, i) => v.id === store_id)[0][
          "business_week_start_day"
        ]
      );
    } catch (error) {
      return 1;
    }
  };

  const getStartQhnum = (storeNames, store_id) => {
    try {
      return storeNames.filter((v, i) => v.id === store_id)[0]["start_qhnum"];
    } catch (error) {
      return 0.0;
    }
  };

  const getLaborPercentEnabled = (storeNames, store_id) => {
    try {
      return storeNames.filter((v, i) => v.id === store_id)[0][
        "labor_percent_enabled"
      ];
    } catch (error) {
      return false;
    }
  };

  return (
    <div
      style={{
        marginLeft: "40px",
        marginRight: "40px",
        marginTop: "20px",
        marginBottom: "20px",
      }}
    >
      <HomeDisplay
        storeOptions={props.storeOptions}
        homeStore={props.homeStore}
        storeNames={props.storeNames}
        change_home_store={props.change_home_store}
        has7ShiftsIntegration={get7ShiftsIntegration(
          props.storeNames,
          props.homeStore
        )}
        businessWeekStartDayIndex={getBusinessWeekStartDayIndex(
          props.storeNames,
          props.homeStore
        )}
        laborPercentEnabled={getLaborPercentEnabled(
          props.storeNames,
          props.homeStore
        )}
        start_qhnum={getStartQhnum(props.storeNames, props.homeStore)}
        breakWaiverEnabled={props.breakWaiverEnabled}
        defaultBreakLengthHours={props.defaultBreakLengthHours}
        windowWidth={props.windowWidth}
        tplhEnabled={props.tplhEnabled}
      />
    </div>
  );
}

export default ContentDisplay;
