import React from "react";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import Divider from "@mui/material/Divider";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import NorthIcon from "@mui/icons-material/North";
import SouthIcon from "@mui/icons-material/South";
import WarningIcon from "@mui/icons-material/Warning";

function DailyMetricsCard(props) {
  const [showTooltipGrade, setShowTooltipGrade] = React.useState(false);
  const [showTooltipStats, setShowTooltipStats] = React.useState(false);

  // Event handlers for mouse enter and leave events on grade tooltip
  const handleMouseEnterGrade = () => {
    setShowTooltipGrade(true);
  };

  const handleMouseLeaveGrade = () => {
    setShowTooltipGrade(false);
  };

  // Event handlers for mouse enter and leave events on stats tooltip
  const handleMouseEnterStats = () => {
    setShowTooltipStats(true);
  };

  const handleMouseLeaveStats = () => {
    setShowTooltipStats(false);
  };

  // Calculate the color for the planned grade based on schedule and forecasted labor
  const planned_letter_color = props.calculate_color(
    props.actual_schedule_labor,
    props.forecasted_labor
  );

  // Calculate the color for the actual grade based on used labor and earned labor
  const actual_letter_color = props.calculate_color(
    props.actual_used_labor_vs_earned,
    props.calculated_earned_labor
  );

  // const show_actuals = !props.edit_mode && props.day_in_past;
  const show_actuals = props.checkedMetricsToggle;

  // Render the +/- metric value based on actual and recommended values
  function showPlusMinus(actual, recommended) {
    if (recommended === 0 || actual === 0) {
      return (
        <div className="text-xxs w-[65px] py-1 rounded flex items-center justify-center gap-0.5">
          <p></p>
        </div>
      );
    }

    // Calculate the difference between actual and recommended values
    const value = actual - recommended;
    if (value <= 0) {
      return (
        <div className="bg-emerald-200 text-emerald-800 text-xxs px-1.5 py-1 rounded flex items-center justify-center gap-0.5">
          <p>{-1 * value} hrs</p>
          <ArrowDownwardIcon style={{ fontSize: "12px" }} />
        </div>
      );
    } else {
      return (
        <div className="bg-rose-200 text-rose-800 text-xxs px-1.5 py-1 rounded flex items-center justify-center gap-0.5">
          <p>{value} hrs</p>
          <ArrowUpwardIcon style={{ fontSize: "12px" }} />
        </div>
      );
    }
  }

  const [showActualSalesBreakdown, setShowActualSalesBreakdown] =
    React.useState(false);

  function toggleShowActualSalesBreakdown() {
    setShowActualSalesBreakdown((prevState) => !prevState);
  }

  const [showPlannedSalesBreakdown, setShowPlannedSalesBreakdown] =
    React.useState(false);

  function toggleShowPlannedSalesBreakdown() {
    setShowPlannedSalesBreakdown((prevState) => !prevState);
  }

  const isLastCard = props.isLastCard;

  // Generate a metric bar with green and gray segments based on value and outOf parameters
  function generateMetricBar(metricName, value, outOf) {
    if (!value) {
      value = 0;
    }

    const grayCount = outOf - value;

    const greenDivs = Array(value).fill(
      <div className="w-7 h-3 rounded-full bg-emerald-600"></div>
    );

    const grayDivs = Array(grayCount).fill(
      <div className="w-7 h-3 rounded-full bg-slate-300"></div>
    );

    return (
      <div className="flex items-center gap-1 mt-3">
        <p className="text-slate-900 w-28 text-sm">{metricName}</p>
        {greenDivs}
        {grayDivs}
      </div>
    );
  }

  function calculatePercentageDifference(actual, forecast) {
    if (forecast === 0) {
      return 0;
    }
    return ((actual - forecast) / forecast) * 100;
  }

  // Calculate the percent difference
  const calculatePercentDifference = (actual, forecasted) => {
    const difference = actual - forecasted;
    const average = (actual + forecasted) / 2;
    const relativeDifference = difference / average;
    const percentDifference = relativeDifference * 100;

    return percentDifference;
  };

  const salesDifference = calculatePercentDifference(
    props.actual_sales,
    props.forecast_sales
  ).toFixed(0);
  const salesDifferenceWeekly = calculatePercentDifference(
    props.actual_sales,
    props.forecast_sales_up_to_current_day
  ).toFixed(0);

  // Actual labor percent calculation
  let totalDayLaborCostActual =
    props.agg_column(props.dailyLaborPercentData, "amount_cents") / 100;
  const averageWageActual =
    totalDayLaborCostActual /
    props.agg_column(props.dailyLaborPercentData, "hours");
  totalDayLaborCostActual =
    props.agg_column(props.actual_used_labor_vs_earned, "hours") *
    averageWageActual;
  const actualLaborPercent =
    (totalDayLaborCostActual / props.actual_sales) * 100;

  // Earned labor percent calculation
  const earnedHours = props.agg_column(props.calculated_earned_labor, "hours");
  const totalDayLaborCostEarned = earnedHours * averageWageActual;
  const earnedLaborPercent =
    (totalDayLaborCostEarned / props.actual_sales) * 100;

  // Scheduled labor percent calculation
  const scheduledHours = props.agg_column(props.actual_schedule_labor, "hours");
  const totalDayLaborCostScheduled =
    scheduledHours * props.averageWageFromPreviousWeek;
  const scheduledLaborPercent =
    (totalDayLaborCostScheduled / props.forecast_sales) * 100;

  // Forecasted labor percent calculation
  const forecastedHours = props.agg_column(props.forecasted_labor, "hours");
  const totalDayLaborCostForecasted =
    forecastedHours * props.averageWageFromPreviousWeek;
  const forecastedLaborPercent =
    (totalDayLaborCostForecasted / props.forecast_sales) * 100;

  const forecastedTransactions = props.daily_forecast_other;

  return (
    <div>
      {!show_actuals ? (
        <div
          className={`${
            props.weekly_or_daily === "weekly"
              ? props.windowWidth > 1100
                ? "w-full"
                : "w-[130px]"
              : "w-full xl:max-w-[110px] lg:max-w-[95px]"
          } pt-3`}
          onMouseEnter={handleMouseEnterStats}
          onMouseLeave={handleMouseLeaveStats}
        >
          <div className="flex items-center justify-between">
            <div className="text-sm text-slate-500">
              {props.day_of_week_str ? props.day_of_week_str : "Total"}
            </div>
            <div
              // onMouseEnter={handleMouseEnterGrade}
              // onMouseLeave={handleMouseLeaveGrade}
              style={{ position: "relative", display: "inline-block" }}
              className="mr-3"
            >
              <div
                style={{
                  fontSize: "16px",
                  color: "white",
                  backgroundColor: planned_letter_color,
                  borderRadius: "8px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "30px",
                  height: "30px",
                  cursor: "default",
                }}
                className="cursor-default"
              >
                {props.calculate_letter(
                  props.actual_schedule_labor,
                  props.forecasted_labor
                )}
              </div>
            </div>
          </div>
          <div
            className="mt-3 w-[95%]"
            // onMouseEnter={handleMouseEnterStats}
            // onMouseLeave={handleMouseLeaveStats}
            style={{ position: "relative", display: "inline-block" }}
          >
            <p className="text-xs ml-1 text-slate-500 xl:hidden">
              {props
                .agg_column(props.actual_schedule_labor, "hours")
                .toFixed(2)}{" "}
              / {props.agg_column(props.forecasted_labor, "hours").toFixed(2)}{" "}
              hrs
            </p>
            <p className="text-xs ml-1 text-slate-500 hidden xl:block">
              {props
                .agg_column(props.actual_schedule_labor, "hours")
                .toFixed(0)}{" "}
              / {props.agg_column(props.forecasted_labor, "hours").toFixed(0)}{" "}
              hrs
            </p>
            <div
              className={`w-full h-4 bg-slate-300 rounded-full overflow-hidden`}
            >
              <div
                className={`h-full rounded-full relative ${
                  (
                    (props.agg_column(props.actual_schedule_labor, "hours") /
                      props.agg_column(props.forecasted_labor, "hours")) *
                    100
                  ).toFixed(1) <= 100
                    ? "bg-slate-800"
                    : "bg-slate-800"
                }`}
                style={{
                  width: `${Math.min(
                    (props.agg_column(props.forecasted_labor, "hours") === 0
                      ? 0
                      : (props.agg_column(
                          props.actual_schedule_labor,
                          "hours"
                        ) /
                          props.agg_column(props.forecasted_labor, "hours")) *
                        100
                    ).toFixed(1),
                    100
                  )}%`,
                }}
              ></div>
            </div>
            <div className="text-sm mt-1 ml-1">
              <p
                className={`${
                  (
                    (props.agg_column(props.actual_schedule_labor, "hours") /
                      props.agg_column(props.forecasted_labor, "hours")) *
                    100
                  ).toFixed(1) <= 100
                    ? "text-emerald-800"
                    : "text-rose-800"
                }`}
              >
                {(props.agg_column(props.forecasted_labor, "hours") === 0
                  ? 0
                  : (props.agg_column(props.actual_schedule_labor, "hours") /
                      props.agg_column(props.forecasted_labor, "hours")) *
                    100
                ).toFixed(1)}
                %{" "}
                <span
                  className={`text-xxs text-slate-500 ${
                    props.weekly_or_daily === "weekly" ? "" : "xl:hidden"
                  }`}
                >
                  of forecasted
                </span>
              </p>
            </div>
            {showTooltipStats && (
              <div
                style={{
                  position: "absolute",
                  maxHeight: "615px",
                  overflow: "auto",
                  bottom: "120px",
                  left: isLastCard ? "-10px" : "120px",
                  transform: "translateX(-50%)",
                  backgroundColor: "white",
                  padding: "25px",
                  borderRadius: "8px",
                  zIndex: "9999",
                  boxShadow:
                    "0 20px 25px -5px rgb(0 0 0 / 0.1), 0 8px 10px -6px rgb(0 0 0 / 0.1)",
                  border: "#e2e8f0 solid 1px",
                }}
                onMouseEnter={handleMouseEnterStats}
                onMouseLeave={handleMouseLeaveStats}
              >
                <h4 className="text-slate-900 font-bold text-sm">
                  Planned Metrics
                </h4>
                <div className="w-full flex items-center justify-between mt-4">
                  <div>
                    <h5 className="text-slate-500 font-bold text-xs">SALES</h5>
                    <p className="text-2xl text-slate-900 mt-1">
                      ${Math.round(props.forecast_sales).toLocaleString()}
                    </p>
                  </div>
                  {props.daily_forecast_other?.length > 0 && (
                    <div
                      className="w-9 h-9 bg-slate-50 hover:bg-slate-100 flex items-center justify-center rounded-full text-slate-500 cursor-pointer"
                      onClick={toggleShowPlannedSalesBreakdown}
                    >
                      {showPlannedSalesBreakdown ? (
                        <ExpandLessIcon />
                      ) : (
                        <ExpandMoreIcon />
                      )}
                    </div>
                  )}
                </div>
                {showPlannedSalesBreakdown && (
                  <div className="mt-5">
                    <h4 className="text-violet-700 text-xs">SALES BREAKDOWN</h4>
                    <div className="text-sm mt-1">
                      {props.daily_forecast_other.map((v, i) => (
                        <div
                          key={i}
                          className="mt-1 flex items-center justify-between"
                        >
                          <p className="text-slate-500">{v.metric}</p>
                          <p className="text-slate-900 text-base">
                            {Math.round(v.value * props.daily_adjustment)}
                          </p>
                        </div>
                      ))}
                    </div>
                  </div>
                )}
                <div className="mt-5">
                  <Divider style={{ backgroundColor: "#e2e8f0" }} />
                </div>
                {props.showLaborPercent ? (
                  <div className="mt-5">
                    <h5 className="text-violet-700 text-xs font-bold">
                      SCHEDULED LABOR
                    </h5>
                    <div className="w-full mt-1">
                      <div className="w-full flex items-center justify-between mt-1">
                        <p className="text-slate-500 text-sm">Labor Percent</p>
                        <p className="text-slate-900 text-base">
                          {isNaN(scheduledLaborPercent)
                            ? "0.00%"
                            : `${scheduledLaborPercent.toFixed(2)}%`}
                        </p>
                      </div>
                      <div className="w-full flex items-center justify-between mt-1">
                        <p className="text-slate-500 text-sm">SPLH</p>
                        <p className="text-slate-900 text-base">
                          $
                          {props.agg_column(
                            props.actual_schedule_labor,
                            "hours"
                          ) === 0
                            ? 0
                            : (
                                props.forecast_sales /
                                props.agg_column(
                                  props.actual_schedule_labor,
                                  "hours"
                                )
                              ).toFixed()}
                        </p>
                      </div>
                      <div className="w-full flex items-center justify-between mt-1">
                        <p className="text-slate-500 text-sm">Total Cost</p>
                        <p className="text-slate-900 text-base">
                          {isNaN(totalDayLaborCostScheduled)
                            ? "$0"
                            : `$${totalDayLaborCostScheduled.toLocaleString(
                                undefined,
                                { maximumFractionDigits: 0 }
                              )}`}
                        </p>
                      </div>
                      <div className="w-full flex items-center justify-between mt-1">
                        <p className="text-slate-500 text-sm">Hours</p>
                        <p className="text-slate-900 text-base">
                          {props
                            .agg_column(props.actual_schedule_labor, "hours")
                            .toFixed(2)}
                        </p>
                      </div>
                      {/* <div className="w-full flex items-center justify-between mt-1">
                        <p className="text-slate-500 text-sm">Average Wage</p>
                        <p className="text-slate-900 text-base">
                          {isNaN(props.averageWageFromPreviousWeek)
                            ? "$0.00"
                            : `$${props.averageWageFromPreviousWeek.toFixed(
                                2
                              )}`}
                        </p>
                      </div> */}
                    </div>
                  </div>
                ) : (
                  <div className="mt-5">
                    <h5 className="text-slate-500 text-xs font-bold">
                      SCHEDULED LABOR
                    </h5>
                    <div className="flex items-center gap-5 mt-1">
                      <div className="flex items-center gap-2 w-28 text-base">
                        {/* <p>Hours:</p> */}
                        <p>
                          {props
                            .agg_column(props.actual_schedule_labor, "hours")
                            .toFixed(2)}{" "}
                          hrs
                        </p>
                      </div>
                      <div className="flex items-center gap-2 text-base">
                        {/* <p>SPLH:</p> */}
                        <p>
                          {!props.tplhEnabled && "$"}
                          {props.agg_column(
                            props.actual_schedule_labor,
                            "hours"
                          ) === 0
                            ? 0
                            : (
                                (props.tplhEnabled
                                  ? props.forecast_transactions
                                  : props.forecast_sales) /
                                props.agg_column(
                                  props.actual_schedule_labor,
                                  "hours"
                                )
                              ).toFixed()}{" "}
                          {props.tplhEnabled ? "TPLH" : "SPLH"}
                        </p>
                      </div>
                    </div>
                  </div>
                )}
                {props.showLaborPercent ? (
                  <div className="mt-4">
                    <h5 className="text-violet-700 text-xs font-bold">
                      FORECASTED LABOR
                    </h5>
                    <div className="w-full mt-1">
                      <div className="w-full flex items-center justify-between mt-1">
                        <p className="text-slate-500 text-sm">Labor Percent</p>
                        <p className="text-slate-900 text-base">
                          {isNaN(forecastedLaborPercent)
                            ? "0.00%"
                            : `${forecastedLaborPercent.toFixed(2)}%`}
                        </p>
                      </div>
                      <div className="w-full flex items-center justify-between mt-1">
                        <p className="text-slate-500 text-sm">SPLH</p>
                        <p className="text-slate-900 text-base">
                          $
                          {props.agg_column(props.forecasted_labor, "hours") ===
                          0
                            ? 0
                            : (
                                props.forecast_sales /
                                props.agg_column(
                                  props.forecasted_labor,
                                  "hours"
                                )
                              ).toFixed()}
                        </p>
                      </div>
                      <div className="w-full flex items-center justify-between mt-1">
                        <p className="text-slate-500 text-sm">Total Cost</p>
                        <p className="text-slate-900 text-base">
                          {isNaN(totalDayLaborCostForecasted)
                            ? "$0"
                            : `$${totalDayLaborCostForecasted.toLocaleString(
                                undefined,
                                { maximumFractionDigits: 0 }
                              )}`}
                        </p>
                      </div>
                      <div className="w-full flex items-center justify-between mt-1">
                        <p className="text-slate-500 text-sm">Hours</p>
                        <p className="text-slate-900 text-base">
                          {props
                            .agg_column(props.forecasted_labor, "hours")
                            .toFixed(2)}
                        </p>
                      </div>
                      {/* <div className="w-full flex items-center justify-between mt-1">
                        <p className="text-slate-500 text-sm">Average Wage</p>
                        <p className="text-slate-900 text-base">
                          {isNaN(props.averageWageFromPreviousWeek)
                            ? "$0.00"
                            : `$${props.averageWageFromPreviousWeek.toFixed(
                                2
                              )}`}
                        </p>
                      </div> */}
                    </div>
                  </div>
                ) : (
                  <div className="mt-4">
                    <h5 className="text-slate-500 text-xs font-bold">
                      FORECASTED LABOR
                    </h5>
                    <div className="flex items-center gap-5 mt-1">
                      <div className="flex items-center gap-2 w-28 text-base">
                        {/* <p>Hours:</p> */}
                        <p>
                          {props
                            .agg_column(props.forecasted_labor, "hours")
                            .toFixed(2)}{" "}
                          hrs
                        </p>
                      </div>
                      <div className="flex items-center gap-2 text-base">
                        {/* <p>SPLH:</p> */}
                        <p>
                          {!props.tplhEnabled && "$"}
                          {props.agg_column(props.forecasted_labor, "hours") ===
                          0
                            ? 0
                            : (
                                (props.tplhEnabled
                                  ? props.forecast_transactions
                                  : props.forecast_sales) /
                                props.agg_column(
                                  props.forecasted_labor,
                                  "hours"
                                )
                              ).toFixed()}{" "}
                          {props.tplhEnabled ? "TPLH" : "SPLH"}
                        </p>
                      </div>
                    </div>
                  </div>
                )}
                <div className="mt-5">
                  <Divider style={{ backgroundColor: "#e2e8f0" }} />
                </div>
                <div className="mt-4">
                  <h4 className="text-slate-500 text-xs font-bold">
                    GRADE BREAKDOWN
                  </h4>
                  {generateMetricBar(
                    "Daily Budget",
                    props.calculate_daily_budget_score(
                      props.actual_schedule_labor,
                      props.forecasted_labor
                    ),
                    5
                  )}
                  {generateMetricBar(
                    "Intraday Coverage",
                    props.calculate_intraday_coverage_score(
                      props.actual_schedule_labor,
                      props.forecasted_labor
                    ),
                    5
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      ) : (
        <div>
          {props.day_in_past ? (
            <div
              className="w-full pt-3"
              onMouseEnter={handleMouseEnterStats}
              onMouseLeave={handleMouseLeaveStats}
            >
              <div className="flex items-center justify-between">
                <div className="text-sm text-slate-500">
                  {props.day_of_week_str ? props.day_of_week_str : "Total"}
                </div>
                <div
                  // onMouseEnter={handleMouseEnterGrade}
                  // onMouseLeave={handleMouseLeaveGrade}
                  style={{ position: "relative", display: "inline-block" }}
                  className="mr-3"
                >
                  <div
                    style={{
                      fontSize: "16px",
                      color: "white",
                      backgroundColor: actual_letter_color,
                      borderRadius: "8px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "30px",
                      height: "30px",
                      cursor: "default",
                    }}
                    className="cursor-default"
                  >
                    {props.calculate_letter(
                      props.actual_used_labor_vs_earned,
                      props.calculated_earned_labor
                    )}
                  </div>
                </div>
              </div>
              <div
                className="mt-3 w-[95%]"
                // onMouseEnter={handleMouseEnterStats}
                // onMouseLeave={handleMouseLeaveStats}
                style={{ position: "relative", display: "inline-block" }}
              >
                <p className="text-xs ml-1 text-slate-500">
                  {props
                    .agg_column(props.actual_used_labor_vs_earned, "hours")
                    .toFixed(2)}{" "}
                  /{" "}
                  {props
                    .agg_column(props.calculated_earned_labor, "hours")
                    .toFixed(2)}{" "}
                  hrs
                </p>
                <div className="w-full h-4 bg-slate-300 rounded-full overflow-hidden">
                  <div
                    className={`h-full rounded-full ${
                      props.agg_column(
                        props.calculated_earned_labor,
                        "hours"
                      ) === 0
                        ? 0
                        : (props.agg_column(
                            props.actual_used_labor_vs_earned,
                            "hours"
                          ) /
                            props.agg_column(
                              props.calculated_earned_labor,
                              "hours"
                            )) *
                            100 <=
                          100
                        ? "bg-slate-800"
                        : "bg-slate-800"
                    }`}
                    style={{
                      width: `${Math.min(
                        props.agg_column(
                          props.calculated_earned_labor,
                          "hours"
                        ) === 0
                          ? 0
                          : (
                              (props.agg_column(
                                props.actual_used_labor_vs_earned,
                                "hours"
                              ) /
                                props.agg_column(
                                  props.calculated_earned_labor,
                                  "hours"
                                )) *
                              100
                            ).toFixed(1),
                        100
                      )}%`,
                    }}
                  ></div>
                </div>
                <div className="text-sm mt-1 ml-1">
                  <p
                    className={`${
                      props.agg_column(
                        props.calculated_earned_labor,
                        "hours"
                      ) === 0
                        ? 0
                        : (props.agg_column(
                            props.actual_used_labor_vs_earned,
                            "hours"
                          ) /
                            props.agg_column(
                              props.calculated_earned_labor,
                              "hours"
                            )) *
                            100 <=
                          100
                        ? "text-emerald-800"
                        : "text-rose-800"
                    }`}
                  >
                    {props.agg_column(
                      props.calculated_earned_labor,
                      "hours"
                    ) === 0
                      ? 0
                      : (
                          (props.agg_column(
                            props.actual_used_labor_vs_earned,
                            "hours"
                          ) /
                            props.agg_column(
                              props.calculated_earned_labor,
                              "hours"
                            )) *
                          100
                        ).toFixed(1)}
                    %{" "}
                    <span className="text-xxs text-slate-500 xl:hidden">
                      of earned
                    </span>
                  </p>
                </div>
                {showTooltipStats && (
                  <div
                    style={{
                      position: "absolute",
                      maxHeight: "615px",
                      overflow: "auto",
                      bottom: "120px",
                      left: isLastCard ? "-10px" : "120px",
                      transform: "translateX(-50%)",
                      backgroundColor: "white",
                      padding: "25px",
                      borderRadius: "8px",
                      zIndex: "9999",
                      boxShadow:
                        "0 20px 25px -5px rgb(0 0 0 / 0.1), 0 8px 10px -6px rgb(0 0 0 / 0.1)",
                      border: "#e2e8f0 solid 1px",
                    }}
                    onMouseEnter={handleMouseEnterStats}
                    onMouseLeave={handleMouseLeaveStats}
                  >
                    <h4 className="text-slate-900 font-bold text-sm">
                      Actual Metrics
                    </h4>
                    <div className="w-full flex items-center justify-between mt-4">
                      <div>
                        <h5 className="text-slate-500 font-bold text-xs">
                          SALES
                        </h5>
                        <div className="mt-1 flex items-center space-x-3">
                          <p className="text-2xl text-slate-900">
                            ${Math.round(props.actual_sales).toLocaleString()}
                          </p>
                          {props.weekly_or_daily === "weekly" ? (
                            isNaN(salesDifferenceWeekly) ? (
                              <div></div>
                            ) : (
                              <div
                                className={`flex items-center justify-end ${
                                  salesDifferenceWeekly < 0
                                    ? "text-rose-800"
                                    : "text-emerald-800"
                                }`}
                              >
                                <div>
                                  {salesDifferenceWeekly < 0 ? (
                                    <SouthIcon
                                      style={{
                                        fontSize: "16px",
                                        marginBottom: "2px",
                                      }}
                                    />
                                  ) : (
                                    <NorthIcon
                                      style={{
                                        fontSize: "16px",
                                        marginBottom: "2px",
                                      }}
                                    />
                                  )}
                                </div>
                                <p>
                                  {Math.abs(salesDifferenceWeekly)}%
                                  <span className="text-xxxs">
                                    {" "}
                                    from forecasted
                                  </span>
                                </p>
                              </div>
                            )
                          ) : isNaN(salesDifference) ? (
                            <div></div>
                          ) : (
                            <div
                              className={`flex items-center justify-end ${
                                salesDifference < 0
                                  ? "text-rose-800"
                                  : "text-emerald-800"
                              }`}
                            >
                              <div>
                                {salesDifference < 0 ? (
                                  <SouthIcon
                                    style={{
                                      fontSize: "16px",
                                      marginBottom: "2px",
                                    }}
                                  />
                                ) : (
                                  <NorthIcon
                                    style={{
                                      fontSize: "16px",
                                      marginBottom: "2px",
                                    }}
                                  />
                                )}
                              </div>
                              <p>
                                {Math.abs(salesDifference)}%
                                <span className="text-xxxs">
                                  {" "}
                                  from forecasted
                                </span>
                              </p>
                            </div>
                          )}
                        </div>
                      </div>
                      {props.daily_actual_other?.length > 0 &&
                        props.daily_forecast_other?.length > 0 && (
                          <div
                            className="w-9 h-9 bg-slate-50 hover:bg-slate-100 flex items-center justify-center rounded-full text-slate-500 cursor-pointer"
                            onClick={toggleShowActualSalesBreakdown}
                          >
                            {showActualSalesBreakdown ? (
                              <ExpandLessIcon />
                            ) : (
                              <ExpandMoreIcon />
                            )}
                          </div>
                        )}
                    </div>
                    {showActualSalesBreakdown && (
                      <div className="mt-5">
                        <h4 className="text-violet-700 text-xs">
                          SALES BREAKDOWN
                        </h4>
                        <div className="text-sm mt-1">
                          {props.daily_actual_other.map((actualValue, i) => {
                            const forecastValue =
                              props.daily_forecast_other.find(
                                (forecast) =>
                                  forecast.metric === actualValue.metric
                              );
                            const percentageDifference = forecastValue
                              ? calculatePercentageDifference(
                                  actualValue.value,
                                  forecastValue.value * props.daily_adjustment
                                )
                              : 0;
                            const Icon =
                              percentageDifference >= 0 ? NorthIcon : SouthIcon; // Assuming SouthIcon exists for negative values
                            const textColorClass =
                              percentageDifference >= 0
                                ? "text-emerald-800"
                                : "text-rose-800";

                            return (
                              <div
                                key={i}
                                className="mt-1 flex items-center justify-between"
                              >
                                <p className="text-slate-500">
                                  {actualValue.metric}
                                </p>
                                <div className="flex items-center">
                                  <p className="text-slate-900 text-base">
                                    {Math.round(actualValue.value)}
                                  </p>
                                  <div
                                    className={`flex items-center justify-end w-16 ${textColorClass}`}
                                  >
                                    <div>
                                      <Icon
                                        style={{
                                          fontSize: "14px",
                                          marginBottom: "2px",
                                        }}
                                      />
                                    </div>
                                    <p>
                                      {Math.abs(
                                        Math.round(percentageDifference)
                                      )}
                                      %
                                    </p>
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    )}
                    <div className="mt-5">
                      <Divider style={{ backgroundColor: "#e2e8f0" }} />
                    </div>
                    {props.showLaborPercent ? (
                      <div className="mt-5">
                        <h5 className="text-violet-700 text-xs font-bold">
                          USED LABOR
                        </h5>
                        <div className="w-full mt-1">
                          <div className="w-full flex items-center justify-between mt-1">
                            <p className="text-slate-500 text-sm">
                              Labor Percent
                            </p>
                            <p className="text-slate-900 text-base">
                              {isNaN(actualLaborPercent)
                                ? "0.00%"
                                : `${actualLaborPercent.toFixed(2)}%`}
                            </p>
                          </div>
                          <div className="w-full flex items-center justify-between mt-1">
                            <p className="text-slate-500 text-sm">SPLH</p>
                            <p className="text-slate-900 text-base">
                              $
                              {props.agg_column(
                                props.actual_used_labor_vs_earned,
                                "hours"
                              ) === 0
                                ? 0
                                : (
                                    props.actual_sales /
                                    props.agg_column(
                                      props.actual_used_labor_vs_earned,
                                      "hours"
                                    )
                                  ).toFixed()}
                            </p>
                          </div>
                          <div className="w-full flex items-center justify-between mt-1">
                            <p className="text-slate-500 text-sm">Total Cost</p>
                            <p className="text-slate-900 text-base">
                              {isNaN(totalDayLaborCostActual)
                                ? "$0"
                                : `$${totalDayLaborCostActual.toLocaleString(
                                    undefined,
                                    { maximumFractionDigits: 0 }
                                  )}`}
                            </p>
                          </div>
                          <div className="w-full flex items-center justify-between mt-1">
                            <p className="text-slate-500 text-sm">Hours</p>
                            <p className="text-slate-900 text-base">
                              {props
                                .agg_column(
                                  props.actual_used_labor_vs_earned,
                                  "hours"
                                )
                                .toFixed(2)}
                            </p>
                          </div>
                          {/* <div className="w-full flex items-center justify-between mt-1">
                            <p className="text-slate-500 text-sm">
                              Average Wage
                            </p>
                            <p className="text-slate-900 text-base">
                              {isNaN(averageWageActual)
                                ? "$0.00"
                                : `$${averageWageActual.toFixed(2)}`}
                            </p>
                          </div> */}
                        </div>
                      </div>
                    ) : (
                      <div className="mt-5">
                        <h5 className="text-slate-500 text-xs font-bold">
                          USED LABOR
                        </h5>
                        <div className="flex items-center gap-5 mt-1">
                          <div className="flex items-center gap-2 w-28 text-base">
                            {/* <p>Hours:</p> */}
                            <p>
                              {props
                                .agg_column(
                                  props.actual_used_labor_vs_earned,
                                  "hours"
                                )
                                .toFixed(2)}{" "}
                              hrs
                            </p>
                          </div>
                          <div className="flex items-center gap-2 text-base">
                            {/* <p>SPLH:</p> */}
                            <p>
                              {!props.tplhEnabled && "$"}
                              {props.agg_column(
                                props.actual_used_labor_vs_earned,
                                "hours"
                              ) === 0
                                ? 0
                                : (
                                    (props.tplhEnabled
                                      ? props.actual_transactions
                                      : props.actual_sales) /
                                    props.agg_column(
                                      props.actual_used_labor_vs_earned,
                                      "hours"
                                    )
                                  ).toFixed()}{" "}
                              {props.tplhEnabled ? "TPLH" : "SPLH"}
                            </p>
                          </div>
                        </div>
                      </div>
                    )}

                    {props.showLaborPercent ? (
                      <div className="mt-4">
                        <h5 className="text-violet-700 text-xs font-bold">
                          EARNED LABOR
                        </h5>
                        <div className="w-full mt-1">
                          <div className="w-full flex items-center justify-between mt-1">
                            <p className="text-slate-500 text-sm">
                              Labor Percent
                            </p>
                            <p className="text-slate-900 text-base">
                              {isNaN(earnedLaborPercent)
                                ? "0.00%"
                                : `${earnedLaborPercent.toFixed(2)}%`}
                            </p>
                          </div>
                          <div className="w-full flex items-center justify-between mt-1">
                            <p className="text-slate-500 text-sm">SPLH</p>
                            <p className="text-slate-900 text-base">
                              $
                              {props.agg_column(
                                props.calculated_earned_labor,
                                "hours"
                              ) === 0
                                ? 0
                                : (
                                    props.actual_sales /
                                    props.agg_column(
                                      props.calculated_earned_labor,
                                      "hours"
                                    )
                                  ).toFixed()}
                            </p>
                          </div>
                          <div className="w-full flex items-center justify-between mt-1">
                            <p className="text-slate-500 text-sm">Total Cost</p>
                            <p className="text-slate-900 text-base">
                              {isNaN(totalDayLaborCostEarned)
                                ? "$0"
                                : `$${totalDayLaborCostEarned.toLocaleString(
                                    undefined,
                                    { maximumFractionDigits: 0 }
                                  )}`}
                            </p>
                          </div>
                          <div className="w-full flex items-center justify-between mt-1">
                            <p className="text-slate-500 text-sm">Hours</p>
                            <p className="text-slate-900 text-base">
                              {props
                                .agg_column(
                                  props.calculated_earned_labor,
                                  "hours"
                                )
                                .toFixed(2)}
                            </p>
                          </div>
                          {/* <div className="w-full flex items-center justify-between mt-1">
                            <p className="text-slate-500 text-sm">
                              Average Wage
                            </p>
                            <p className="text-slate-900 text-base">
                              {isNaN(averageWageActual)
                                ? "$0.00"
                                : `$${averageWageActual.toFixed(2)}`}
                            </p>
                          </div> */}
                        </div>
                      </div>
                    ) : (
                      <div className="mt-4">
                        <h5 className="text-slate-500 text-xs font-bold">
                          EARNED LABOR
                        </h5>
                        <div className="flex items-center gap-5 mt-1">
                          <div className="flex items-center gap-2 w-28 text-base">
                            {/* <p>Hours:</p> */}
                            <p>
                              {props
                                .agg_column(
                                  props.calculated_earned_labor,
                                  "hours"
                                )
                                .toFixed(2)}{" "}
                              hrs
                            </p>
                          </div>
                          <div className="flex items-center gap-2 text-base">
                            {/* <p>SPLH:</p> */}
                            <p>
                              {!props.tplhEnabled && "$"}
                              {props.agg_column(
                                props.calculated_earned_labor,
                                "hours"
                              ) === 0
                                ? 0
                                : (
                                    (props.tplhEnabled
                                      ? props.actual_transactions
                                      : props.actual_sales) /
                                    props.agg_column(
                                      props.calculated_earned_labor,
                                      "hours"
                                    )
                                  ).toFixed()}{" "}
                              {props.tplhEnabled ? "TPLH" : "SPLH"}
                            </p>
                          </div>
                        </div>
                      </div>
                    )}
                    <div className="mt-5">
                      <Divider style={{ backgroundColor: "#e2e8f0" }} />
                    </div>
                    <div className="mt-5">
                      <h4 className="text-slate-500 text-xs font-bold">
                        GRADE BREAKDOWN
                      </h4>
                      {generateMetricBar(
                        "Daily Budget",
                        props.calculate_daily_budget_score(
                          props.actual_used_labor_vs_earned,
                          props.calculated_earned_labor
                        ),
                        5
                      )}
                      {generateMetricBar(
                        "Intraday Coverage",
                        props.calculate_intraday_coverage_score(
                          props.actual_used_labor_vs_earned,
                          props.calculated_earned_labor
                        ),
                        5
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
          ) : (
            <div className="h-24">
              <p className="text-xs text-slate-500">No Data Available</p>
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default DailyMetricsCard;
