import React from "react";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import StackedBarChartIcon from "@mui/icons-material/StackedBarChart";
import StackedLineChartIcon from "@mui/icons-material/StackedLineChart";
import IconButton from "@mui/material/IconButton";
import VisualButtonModal from "./VisualButtonModal";
import ListAltIcon from "@mui/icons-material/ListAlt";
import Tooltip from "@mui/material/Tooltip";
import VisualChartModal from "./VisualChartModal";
import VisualDailyReportModal from "./VisualDailyReportModal";
//import SummarizeIcon from "@mui/icons-material/Summarize";
import TableViewIcon from "@mui/icons-material/TableView";
import VisualDailyReportModal2 from "./VisualDailyReportModal2";
import { generateLaborSummary, get_t_str } from "../../../../helpers/helpers";

function VisualButtonOptions(props) {
  const [openChart, setOpenChart] = React.useState(false);
  const handleOpenChart = () => setOpenChart(true);
  const handleCloseChart = () => setOpenChart(false);

  const [openDailyReport, setOpenDailyReport] = React.useState(false);
  const handleOpenDailyReport = () => setOpenDailyReport(true);
  const handleCloseDailyReport = () => setOpenDailyReport(false);

  const [openDailyReport2, setOpenDailyReport2] = React.useState(false);
  const handleOpenDailyReport2 = () => setOpenDailyReport2(true);
  const handleCloseDailyReport2 = () => setOpenDailyReport2(false);

  const [title, setTitle] = React.useState("");
  const [description, setDescription] = React.useState("");

  const clickChartButton = (new_title, new_description) => {
    setTitle(new_title);
    setDescription(new_description);
    handleOpenChart();
  };

  const data = generateLaborSummary(
    props.forecasted_labor_day,
    props.actual_schedule_labor_day,
    get_t_str,
    "Forecasted",
    "Scheduled"
  );

  const clickDailyReportButton = (new_title, new_description) => {
    setTitle(new_title);
    setDescription(new_description);
    handleOpenDailyReport();
  };

  const clickDailyReportButton2 = (new_title, new_description) => {
    setTitle(new_title);
    setDescription(new_description);
    handleOpenDailyReport2();
  };

  const coverageGraphDescriptions =
    "The Workload Graph shows the total forecasted minutes of work across the day.";
  const dailyReportDescription =
    "The Daily Report let's you see an overview of the labor plan for the day.";

  console.log("VISUALBUTTONOPTIONS", props.start_qhnum);
  return (
    <div>
      <div className="-ml-1.5 w-36 xl:w-32 flex items-center">
        <Tooltip title="Intraday Staffing Levels Expanded">
          <IconButton
            disabled={
              props.agg_column(props.forecasted_labor_day, "labor") === 0 ||
              (props.checkedMetricsToggle &&
                props.agg_column(
                  props.actual_used_labor_vs_earned_day,
                  "labor"
                ) === 0)
            }
            onClick={() =>
              clickChartButton("Intraday Graph", coverageGraphDescriptions)
            }
          >
            <StackedLineChartIcon />
          </IconButton>
        </Tooltip>
        {/* <Tooltip title="Daily Report">
          <IconButton
            disabled={
              props.forecasted_labor_day.length === 0 ||
              props.checkedMetricsToggle
            }
            onClick={() =>
              clickDailyReportButton("Daily Report", dailyReportDescription)
            }
          >
            <TableViewIcon />
          </IconButton>
        </Tooltip> */}
        <Tooltip title="Daily Report New!">
          <IconButton
            disabled={
              props.actual_schedule_labor_day.length === 0 ||
              props.checkedMetricsToggle
            }
            onClick={() =>
              clickDailyReportButton2("Daily Report2", dailyReportDescription)
            }
          >
            <ListAltIcon />
          </IconButton>
        </Tooltip>
      </div>
      <VisualChartModal
        open={openChart}
        handleClose={handleCloseChart}
        forecasted_labor_day={props.forecasted_labor_day}
        forecasted_workload_day={props.forecasted_workload_day}
        recommended_schedule_labor_day={props.recommended_schedule_labor_day}
        actual_schedule_labor_day={props.actual_schedule_labor_day}
        calculated_earned_labor_day={props.calculated_earned_labor_day}
        actual_used_labor_vs_earned_day={props.actual_used_labor_vs_earned_day}
        actual_schedule={props.actual_schedule}
        agg_column={props.agg_column}
        get_t_str={props.get_t_str}
        combine_data={props.combine_data}
        date_name={props.date_name}
        earned_toggle={props.checkedMetricsToggle}
      />
      <VisualDailyReportModal
        open={openDailyReport}
        handleClose={handleCloseDailyReport}
        forecasted_labor_day={props.forecasted_labor_day}
        forecasted_workload_day={props.forecasted_workload_day}
        recommended_schedule_labor_day={props.recommended_schedule_labor_day}
        actual_schedule_labor_day={props.actual_schedule_labor_day}
        actual_schedule={props.actual_schedule}
        agg_column={props.agg_column}
        get_t_str={props.get_t_str}
        combine_data={props.combine_data}
        date_name={props.date_name}
        store_team_member_weekly_info={props.store_team_member_weekly_info}
        daily_batch_guidance={props.daily_batch_guidance}
        forecast_sales={props.forecast_sales}
        calculate_daily_budget_score={props.calculate_daily_budget_score}
        calculate_intraday_coverage_score={
          props.calculate_intraday_coverage_score
        }
        calculate_letter={props.calculate_letter}
        calculate_color={props.calculate_color}
        daily_weather={props.daily_weather}
        role_types={props.role_types}
        role_names={props.role_names}
      />
      <VisualDailyReportModal2
        open={openDailyReport2}
        handleClose={handleCloseDailyReport2}
        forecasted_labor_day={props.forecasted_labor_day}
        forecasted_workload_day={props.forecasted_workload_day}
        recommended_schedule_labor_day={props.recommended_schedule_labor_day}
        actual_schedule_labor_day={props.actual_schedule_labor_day}
        actual_schedule={props.actual_schedule}
        agg_column={props.agg_column}
        get_t_str={props.get_t_str}
        combine_data={props.combine_data}
        date_name={props.date_name}
        date_str={props.date_str}
        store_team_member_weekly_info={props.store_team_member_weekly_info}
        daily_batch_guidance={props.daily_batch_guidance}
        daily_intraday_metrics={props.daily_intraday_metrics}
        forecast_sales={props.forecast_sales}
        calculate_daily_budget_score={props.calculate_daily_budget_score}
        calculate_intraday_coverage_score={
          props.calculate_intraday_coverage_score
        }
        calculate_letter={props.calculate_letter}
        calculate_color={props.calculate_color}
        daily_weather={props.daily_weather}
        role_types={props.role_types}
        role_names={props.role_names}
        start_qhnum={props.start_qhnum}
      />
    </div>
  );
}

export default VisualButtonOptions;
