import React from "react";
import Stack from "@mui/material/Stack";
import SaveScheduleButtons from "./save_schedule_buttons/SaveScheduleButtons";
import FilterOrderButtons from "./FilterOrderButtons";
import EditScheduleButtonsGrouping from "./load_schedule_buttons/EditScheduleButtonsGrouping";
import FlagIcon from "@mui/icons-material/Flag";
import Tooltip from "@mui/material/Tooltip";

function ActionBarDisplay(props) {
  return (
    <div
      style={{
        width: "100%",
        backgroundColor: "#eeeeee",
        borderRadius: "10px",
        padding: "10px",
      }}
    >
      <Stack
        direction="row"
        spacing={4}
        justifyContent="space-between"
        style={{ position: "relative" }}
      >
        <div className="w-80">
          <FilterOrderButtons
            toggleDisplayMode={props.toggleDisplayMode}
            displayMode={props.displayMode}
            toggleMinimizeMode={props.toggleMinimizeMode}
            minimizeMode={props.minimizeMode}
            filterTeam={props.filterTeam}
            filtered={props.filtered}
            sortTeam={props.sortTeam}
            sorted={props.sorted}
            setSorted={props.setSorted}
            store_team_member_weekly_info_view={
              props.store_team_member_weekly_info_view
            }
            actual_schedule={props.actual_schedule}
            get_t_str={props.get_t_str}
            addDays={props.addDays}
            week_start_date={props.week_start_date}
            weeklyView={props.weeklyView}
            notes={props.notes}
            role_names={props.role_names}
          />
        </div>
        <div>
          <EditScheduleButtonsGrouping
            week_already_started={props.week_already_started}
            week_ended={props.week_ended}
            autosched_available={props.autosched_available}
            loadFrom7Shift={props.loadFrom7Shift}
            loadFromTemplate={props.loadFromTemplate}
            template_names={props.template_names}
            week_start_date={props.week_start_date}
            deleteScheduleTemplate={props.deleteScheduleTemplate}
            load7ShiftsScheduleFinished={props.load7ShiftsScheduleFinished}
            setLoad7ShiftsScheduleFinished={
              props.setLoad7ShiftsScheduleFinished
            }
            loadFromTemplateFinished={props.loadFromTemplateFinished}
            setLoadFromTemplateFinished={props.setLoadFromTemplateFinished}
            autoSchedule={props.autoSchedule}
            acceptNewSchedule={props.acceptNewSchedule}
            schedule_differences={props.schedule_differences}
            autoScheduleFinished={props.autoScheduleFinished}
            autoScheduleError={props.autoScheduleError}
            setAutoScheduleError={props.setAutoScheduleError}
            setAutoScheduleFinished={props.setAutoScheduleFinished}
            acceptAutoScheduleFinished={props.acceptAutoScheduleFinished}
            setAcceptAutoScheduleFinished={props.setAcceptAutoScheduleFinished}
            loadedFrom7Shifts={props.loadedFrom7Shifts}
            loadedFromTemplate={props.loadedFromTemplate}
            loadedFromAuto={props.loadedFromAuto}
            lockAllShifts={props.lockAllShifts}
            setLockAllShifts={props.setLockAllShifts}
            store_team_member_weekly_info={props.store_team_member_weekly_info}
            get_t_str={props.get_t_str}
            actual_schedule={props.actual_schedule}
            has7ShiftsIntegration={props.has7ShiftsIntegration}
            role_types={props.role_types}
            role_names={props.role_names}
          />
        </div>
        <div className="w-80 flex items-center justify-end">
          <SaveScheduleButtons
            week_start_date={props.week_start_date}
            week_already_started={props.week_already_started}
            saveScheduleAsTemplate={props.saveScheduleAsTemplate}
            template_names={props.template_names}
            formatDate={props.formatDate}
            deleteAllShifts={props.deleteAllShifts}
            scheduled_hours={props.scheduled_hours}
            publishShifts={props.publishShifts}
            roleType={props.roleType}
            roleTypeOptions={props.roleTypeOptions}
            publishShiftsFinished={props.publishShiftsFinished}
            setPublishShiftsFinished={props.setPublishShiftsFinished}
            pushedChanges={props.pushedChanges}
            has7ShiftsIntegration={props.has7ShiftsIntegration}
            countOfWarnings={props.countOfWarnings}
            countOfUnpublished={props.countOfUnpublished}
            countOfPublished={props.countOfPublished}
            showUnpublishedShifts={props.showUnpublishedShifts}
            setShowUnpublishedShifts={props.setShowUnpublishedShifts}
          />
        </div>
      </Stack>
    </div>
  );
}

export default ActionBarDisplay;
