import React, { useState, useEffect } from "react";
import ActionBarDisplay from "./action_bar_components/ActionBarDisplay";
import ScheduleDisplay from "./schedule_components/ScheduleDisplay";
import TopBarDisplay from "./top_bar_components/TopBarDisplay";
import DailyTopBarDisplay from "../plan/daily_top_bar_components/DailyTopBarDisplay";
import axios from "axios";
import {
  API_get_forecasted_sales_daily,
  API_get_forecasted_transactions_daily,
  API_get_forecasted_other_daily,
  API_get_adjustments_chosen,
  API_get_forecasted_labor,
  API_get_forecasted_workload,
  API_get_recommended_schedule,
  API_get_recommended_schedule_labor_deployment,
  API_get_actual_schedule,
  API_get_actual_schedule_labor_deployment,
  API_get_store_team_members,
  API_load_prev_week_schedule,
  API_load_employee_prev_week_schedule,
  API_get_template_names,
  API_save_schedule_as_template,
  API_load_schedule_from_template,
  API_delete_schedule_template,
  API_edit_shift,
  API_get_default_availability,
  API_request_time_off,
  API_get_default_target_hours,
  API_get_weekly_target_hours,
  API_get_request_off,
  API_edit_availability,
  API_get_available_roles,
  API_get_role_names,
  API_update_team_member_info,
  API_auto_schedule,
  API_accept_auto_schedule,
  API_delete_all_shifts,
  API_publish_schedule,
  API_get_actual_sales_daily,
  API_get_actual_other_daily,
  API_get_calculated_earned_labor,
  API_get_actual_used_labor_vs_earned,
  API_get_recommended_used_labor_vs_earned,
  API_add_forecast_adjustment,
  API_edit_multiple_shifts,
  API_get_default_operating_hours,
  API_edit_operating_hours,
  API_get_daily_operating_hours,
  API_get_role_types,
  API_get_weather,
  API_get_events,
  API_save_event,
  API_delete_event,
  API_get_batch_guidance,
  API_get_intraday_metrics,
  API_get_local_events,
  API_get_all_time_off_requests,
  API_get_published_shift_count,
  API_get_station_info,
  API_get_forecast_exclude_dates,
  API_save_forecast_exclude_date,
  API_delete_forecast_exclude_date,
  API_get_spent_labor_daily_summaries,
  API_get_recent_avg_wages,
  API_list_frequent_schedules,
  API_get_historical_sales_context,
  API_get_grade_weights,
  API_post_missed_shift,
} from "../../../constants";

import { getDefaultAxiosConfig } from "../../../utilities/requests";
import { get_t_str, getRoleType } from "../../../helpers/helpers";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { postRightworkAPI } from "../requests/utilities/requests";

function getStartOfCurrentWeek(businessWeekStartDayIndex) {
  const today = new Date();
  const first = today.getDate() - today.getDay() + businessWeekStartDayIndex;

  const monday = new Date(today.setDate(first));
  return monday;
}

function HomeDisplay(props) {
  const [displayMode, setDisplayMode] = React.useState(false);
  const [minimizeMode, setMinimizeMode] = React.useState(false);
  const [roleType, setRoleType] = React.useState([]);
  const [roleTypeOptions, setRoleTypeOptions] = React.useState([]);

  const [filtered, setFiltered] = React.useState("");
  const [sorted, setSorted] = React.useState("");
  const [showWarnings, setShowWarnings] = React.useState(false);
  const [searchInput, setSearchInput] = React.useState("");
  const [noMatchingSearch, setNoMatchingSearch] = React.useState(false);
  const [showUnpublishedShifts, setShowUnpublishedShifts] =
    React.useState(false);

  const [showLaborPercent, setShowLaborPercent] = React.useState(
    props.laborPercentEnabled
  );

  const [week_start_date, set_week_start_date] = useState(
    getStartOfCurrentWeek(props.businessWeekStartDayIndex)
  );
  const [all_data_full, set_all_data_full] = useState({
    store_team_members: [],
  });
  const [all_data, set_all_data] = useState({ store_team_members: [] });
  const [template_names, set_template_names] = useState([]);
  const [historical_sales_context, set_historical_sales_context] = useState([]);

  const [load7ShiftsScheduleFinished, setLoad7ShiftsScheduleFinished] =
    React.useState(false);
  const [loadFromTemplateFinished, setLoadFromTemplateFinished] =
    React.useState(false);

  const [schedule_differences, set_schedule_differences] = useState({});
  const [autoScheduleFinished, setAutoScheduleFinished] = useState(false);
  const [autoScheduleError, setAutoScheduleError] = useState(false);
  const [acceptAutoScheduleFinished, setAcceptAutoScheduleFinished] =
    useState(false);

  const [saveShiftFinished, setSaveShiftFinished] = useState(false);
  const [saveAvailabilityFinished, setSaveAvailabilityFinished] =
    useState(false);
  const [publishShiftsFinished, setPublishShiftsFinished] = useState(false);

  const [lockAllShifts, setLockAllShifts] = useState(false);

  const toggleLockAllShifts = () => {
    setLockAllShifts((prevState) => !prevState);
  };

  const [loading, setLoading] = useState(false);

  const [loadedFrom7Shifts, setLoadedFrom7Shifts] = useState(false);
  const [loadedFromTemplate, setLoadedFromTemplate] = useState(false);
  const [loadedFromAuto, setLoadedFromAuto] = useState(false);

  const [addAdjustmentFinished, setAddAdjustmentFinished] = useState(false);

  const [saveEventFinished, setSaveEventFinished] = useState(false);

  const [pushedChanges, setPushedChanges] = useState({
    pushed: false,
    lastPublishedDate: "Nov 2, 2022",
    lastPublishedTime: "10:22pm",
  });

  const [date, setDate] = useState();

  const axiosConfig = getDefaultAxiosConfig();

  function formatDate(date) {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  }

  function addDays(theDate, days) {
    var newDate = new Date(theDate);
    newDate.setDate(theDate.getDate() + days);
    return newDate;
  }

  function increment_day(date, days) {
    const newDate = addDays(date, days);
    setDate(newDate);

    // Calculate new day of week
    let newDow = (dowSelected + days) % 7;
    newDow = newDow < 0 ? 7 + newDow : newDow; // Adjust for negative values

    // Set the day of the week
    setDowSelected((prevDow) => newDow);

    // Calculate potential new week start date
    let potentialWeekStartDate;
    if (newDow === 0) {
      // If the new day is Sunday, then it's the start of a new week
      potentialWeekStartDate = newDate;
    } else {
      // Otherwise, find the start of the week
      potentialWeekStartDate = addDays(newDate, -newDow);
    }

    // Update week start date only if it's different from the current one
    if (!isSameDate(potentialWeekStartDate, week_start_date)) {
      set_week_start_date(potentialWeekStartDate);
    }
  }

  // Helper function to compare dates
  function isSameDate(date1, date2) {
    return (
      date1.getFullYear() === date2.getFullYear() &&
      date1.getMonth() === date2.getMonth() &&
      date1.getDate() === date2.getDate()
    );
  }

  const [weeklyView, setWeeklyView] = React.useState(true);
  const [dowSelected, setDowSelected] = React.useState(0);
  function toggleDailyWeekly(d) {
    const weekly_curr = weeklyView;
    setWeeklyView(!weekly_curr);
    setDowSelected(d);
    setFiltered("");
    if (weekly_curr) {
      setSorted("shift_start");
    } else {
      setSorted("");
    }

    const selectedDate = addDays(week_start_date, d);
    setDate(selectedDate);
  }

  const [lockedDays, setLockedDays] = React.useState([
    false,
    false,
    false,
    false,
    false,
    false,
    false,
  ]);
  const [lockedPeople, setLockedPeople] = React.useState({});
  function lock_day(dow) {
    let newLockedDays = [...lockedDays];
    let newLockedDaysValue = newLockedDays[dow];
    newLockedDays[dow] = !newLockedDaysValue;
    setLockedDays(newLockedDays);
  }
  function lock_person(id) {
    let newLockedPeople = { ...lockedPeople };
    const newLockedPeopleValue = newLockedPeople[id];
    newLockedPeople[id] = !newLockedPeopleValue;
    setLockedPeople(newLockedPeople);
  }

  const store_id = props.homeStore;

  function toggleDisplayMode() {
    setDisplayMode((prevState) => !prevState);
  }

  function toggleMinimizeMode() {
    setMinimizeMode((prevState) => !prevState);
  }

  function filterTeam(value) {
    setFiltered(value);
  }

  function sortTeam(value) {
    setSorted(value);
  }

  function loadTemplateNames() {
    const url = API_get_template_names + store_id;
    axios.get(url, axiosConfig).then((res) => {
      set_template_names(res.data);
    });
  }

  async function saveScheduleAsTemplate(template_name) {
    try {
      // Show loading toast while saving schedule
      toast.loading("Saving Schedule as Template...", { autoClose: false });

      // Update template_name in each element of actual_schedule
      all_data.actual_schedule.forEach((element) => {
        element.template_name = template_name;
      });

      // Send POST request to save schedule as template
      const axiosConfig = getDefaultAxiosConfig();
      const response = await axios.post(
        API_save_schedule_as_template + store_id,
        all_data.actual_schedule,
        axiosConfig
      );

      // If request is successful, show success toast and perform additional actions
      if (response.status === 201) {
        toast.dismiss();
        toast.success("Schedule Saved as Template!", { autoClose: 3000 });

        // Reload template names after successful save
        loadTemplateNames();
      }
    } catch (error) {
      // Dismiss loading toast on error
      toast.dismiss();

      // Display error message based on response
      if (error.response?.data?.system?.message) {
        toast.error(error.response?.data?.system?.message, { autoClose: 3000 });
      } else {
        toast.error("Request Failed", { autoClose: 3000 });
      }

      console.error("Error:", error); // Handle error as needed
    }
  }

  function loadEvents() {
    const url =
      API_get_events +
      store_id +
      "&date_start=" +
      formatDate(week_start_date.toString()) +
      "&date_end=" +
      formatDate(addDays(week_start_date, 6).toString());
    axios.get(url, axiosConfig).then((res) => {
      setSaveEventFinished(true);
      fetch_data([API_get_events], ["events"], false);
    });
  }

  function saveEvent(event_data) {
    console.log(event_data);
    event_data["store"] = store_id;
    axios.post(API_save_event, event_data, axiosConfig).then(() => {
      loadEvents();
    });
  }

  function deleteEvent(event_id) {
    axios.delete(API_delete_event + event_id, axiosConfig).then(() => {
      loadEvents();
    });
  }

  function loadForecastExcludeDates() {
    const url =
      API_get_forecast_exclude_dates +
      store_id +
      "&date_start=" +
      formatDate(week_start_date.toString()) +
      "&date_end=" +
      formatDate(addDays(week_start_date, 6).toString());
    axios.get(url, axiosConfig).then((res) => {
      fetch_data(
        [API_get_forecast_exclude_dates],
        ["forecast_exclude_dates"],
        false
      );
    });
  }

  function loadHistoricalSalesContext() {
    const url =
      API_get_historical_sales_context +
      store_id +
      "&date=" +
      formatDate(week_start_date.toString());
    axios.get(url, axiosConfig).then((res) => {
      set_historical_sales_context(res.data);
    });
  }

  console.log(
    "HISTORICAL SALES CONTEXT",
    historical_sales_context?.redux?.payload?.data
  );

  function saveForecastExcludeDate(exclude_data) {
    exclude_data["store"] = store_id;
    axios
      .post(API_save_forecast_exclude_date, exclude_data, axiosConfig)
      .then(() => {
        loadForecastExcludeDates();
      });
  }

  function deleteForecastExcludeDate(date_str) {
    axios
      .delete(
        API_delete_forecast_exclude_date + store_id + "&date=" + date_str,
        axiosConfig
      )
      .then(() => {
        loadForecastExcludeDates();
      });
  }

  function loadFromTemplate(template_name) {
    setLoadFromTemplateFinished(false);
    const url =
      API_load_schedule_from_template +
      store_id +
      "&date_start=" +
      formatDate(week_start_date.toString());
    axios
      .put(
        url,
        {
          role_type: roleType,
          template_name: template_name,
        },
        axiosConfig
      )
      .then(() => {
        setLoadFromTemplateFinished(true);
        fetch_data(
          actual_schedule_api_calls,
          actual_schedule_set_data_names,
          false
        );
      });
    setLoadedFromTemplate(true);
    setLoadedFrom7Shifts(false);
    setLoadedFromAuto(false);
  }

  // function deleteScheduleTemplate(template_name) {
  //   const url =
  //     API_delete_schedule_template +
  //     store_id +
  //     "&date_start=" +
  //     formatDate(week_start_date.toString()) +
  //     "&template_name=" +
  //     template_name;
  //   axios.delete(url, axiosConfig).then(() => {
  //     loadTemplateNames();
  //   });
  // }

  async function deleteScheduleTemplate(template_name) {
    try {
      // Show loading toast while deleting schedule template
      toast.loading("Deleting Schedule Template...", { autoClose: false });

      // Construct URL for DELETE request
      const url =
        API_delete_schedule_template +
        store_id +
        "&date_start=" +
        formatDate(week_start_date.toString()) +
        "&template_name=" +
        template_name;

      // Send DELETE request to delete schedule template
      await axios.delete(url, axiosConfig);

      // If deletion is successful, show success toast and reload template names
      toast.dismiss();
      toast.success("Schedule Template Deleted!", { autoClose: 3000 });
      loadTemplateNames();
    } catch (error) {
      // Dismiss loading toast on error
      toast.dismiss();

      // Display error message based on response
      if (error.response?.data?.system?.message) {
        toast.error(error.response?.data?.system?.message, { autoClose: 3000 });
      } else {
        toast.error("Request Failed", { autoClose: 3000 });
      }

      console.error("Error:", error); // Handle error as needed
    }
  }

  function addShift(shift_info, shift_save_type, appliedTo, outside_of_modal) {
    let dates_changed = [];
    let todays_date = new Date(new Date().toDateString());
    appliedTo.forEach((d, i) => {
      if (d) {
        let new_date = new Date(addDays(week_start_date, i).toDateString());
        if (new_date >= todays_date) {
          dates_changed.push(formatDate(new_date.toString()));
        }
      }
    });
    console.log(dates_changed);
    shift_info["dates_changed"] = dates_changed;
    axios
      .put(
        API_edit_shift +
          store_id +
          "&shift_id=" +
          shift_info.id +
          "&action=" +
          shift_save_type,
        shift_info,
        axiosConfig
      )
      .then(() => {
        fetch_data(
          actual_schedule_api_calls,
          actual_schedule_set_data_names,
          false
        );
        setSaveShiftFinished(outside_of_modal ? false : true);
      });
    setLoadedFrom7Shifts(false);
    setLoadedFromTemplate(false);
    setLoadedFromAuto(false);
  }

  function addMultipleShifts(shifts_info) {
    console.log(shifts_info);
    axios
      .put(API_edit_multiple_shifts + store_id, shifts_info, axiosConfig)
      .then(() => {
        fetch_data(
          actual_schedule_api_calls,
          actual_schedule_set_data_names,
          false
        );
        setSaveShiftFinished(true);
      });
    setLoadedFrom7Shifts(false);
    setLoadedFromTemplate(false);
    setLoadedFromAuto(false);
  }

  function editAvailability(availability_info, appliedTo) {
    let dates_changed = [];
    appliedTo.forEach((d, i) => {
      if (d) {
        let new_date = addDays(week_start_date, i);
        dates_changed.push(formatDate(new_date.toString()));
      }
    });
    availability_info["dates_changed"] = dates_changed;

    axios
      .put(
        API_edit_availability +
          store_id +
          "&default_checked=" +
          availability_info["default"].toString(),
        availability_info,
        axiosConfig
      )
      .then(() => {
        fetch_data(team_member_api_calls, team_member_set_data_names, false);
        setSaveAvailabilityFinished(true);
      });
  }

  const request_time_off = async (
    title,
    note,
    date_lower_bound,
    date_upper_bound,
    stm_id
  ) => {
    // Prepare data to send

    const requestData = {
      store_id: props.homeStore,
      title: title,
      note: note,
      date_lower_bound: date_lower_bound,
      date_upper_bound: date_upper_bound,
      stm_id: stm_id,
    };

    try {
      toast.loading("Creating Time Off...", { autoClose: false });
      const res = await postRightworkAPI(API_request_time_off, requestData);

      if (res && res.status === 200) {
        toast.dismiss();
        toast.success("Time Off Creation Successful!", { autoClose: 3000 });
        fetch_data(team_member_api_calls, team_member_set_data_names, false);
      }
    } catch (error) {
      // Handle network error
      if (error.response?.data?.system?.message) {
        toast.dismiss();
        toast.error(error.response?.data?.system?.message, { autoClose: 3000 });
      } else {
        toast.dismiss();
        toast.error("Request Failed");
      }
    }
  };

  function loadFrom7Shifts(weeks_back) {
    setLoad7ShiftsScheduleFinished(false);
    axios
      .put(
        API_load_prev_week_schedule + store_id,
        {
          store_id: store_id,
          date_start: formatDate(week_start_date.toString()),
          weeks_back: weeks_back,
          role_type: roleType,
        },
        axiosConfig
      )
      .then(() => {
        setLoad7ShiftsScheduleFinished(true);
        fetch_data(
          actual_schedule_api_calls,
          actual_schedule_set_data_names,
          true
        );
      });
    setLoadedFromTemplate(false);
    setLoadedFromAuto(false);
  }

  function loadEmployeePreviousSchedule(store_team_member_id) {
    axios
      .put(
        API_load_employee_prev_week_schedule + store_id,
        {
          store_id: store_id,
          store_team_member_id: store_team_member_id,
          date_start: formatDate(week_start_date.toString()),
        },
        axiosConfig
      )
      .then(() => {
        fetch_data(
          actual_schedule_api_calls,
          actual_schedule_set_data_names,
          true
        );
      });
  }

  function edit_hoo(hoo_info, default_checked) {
    console.log(hoo_info);
    console.log(default_checked);
    hoo_info["store"] = store_id;
    let default_param = "daily";
    if (default_checked) {
      default_param = "default";
    }
    axios
      .put(
        API_edit_operating_hours + store_id + "&default_param=" + default_param,
        hoo_info,
        axiosConfig
      )
      .then(() => {
        fetch_data(
          [API_get_default_operating_hours, API_get_daily_operating_hours],
          ["default_operating_hours", "daily_operating_hours"],
          false
        );
      });
  }

  async function deleteAllShifts() {
    try {
      // Show loading toast while deleting all shifts
      toast.loading("Deleting All Shifts...", { autoClose: false });

      let locked_people_ids = [];
      all_data.store_team_members.forEach((x) => {
        if (lockedPeople[x.id]) {
          locked_people_ids.push(x.scheduling_app_user_id);
        }
      });

      let newLockedDays = [...lockedDays];
      if (!weeklyView) {
        newLockedDays = newLockedDays.map((value, i) => {
          if (i === dowSelected) {
            return value;
          } else {
            return true;
          }
        });
      }

      const locked_data = {
        locked_days: newLockedDays,
        locked_people: locked_people_ids,
        role_type: roleType,
      };
      const lock_type = lockAllShifts ? "locked" : "unlocked";

      // Construct URL for PUT request
      const url =
        API_delete_all_shifts +
        store_id +
        "&date_start=" +
        formatDate(week_start_date.toString()) +
        "&date_end=" +
        formatDate(addDays(week_start_date, 6).toString()) +
        "&lock_type=" +
        lock_type;

      // Send PUT request to delete all shifts
      await axios.put(url, locked_data, axiosConfig);

      // If deletion is successful, show success toast and fetch data
      toast.dismiss();
      toast.success("All Shifts Deleted!", { autoClose: 3000 });

      fetch_data(
        actual_schedule_api_calls,
        actual_schedule_set_data_names,
        false
      );

      setLoadedFrom7Shifts(false);
      setLoadedFromTemplate(false);
      setLoadedFromAuto(false);
    } catch (error) {
      // Dismiss loading toast on error
      toast.dismiss();

      // Display error message based on response
      if (error.response?.data?.system?.message) {
        toast.error(error.response?.data?.system?.message, { autoClose: 3000 });
      } else {
        toast.error("Request Failed", { autoClose: 3000 });
      }

      console.error("Error:", error); // Handle error as needed
    }
  }

  function publishShifts(roleTypeFilter) {
    const url =
      API_publish_schedule +
      store_id +
      "&date_start=" +
      formatDate(week_start_date.toString()) +
      "&date_end=" +
      formatDate(addDays(week_start_date, 6).toString()) +
      (roleTypeFilter ? "&role_type_filter=" + roleTypeFilter : "");
    axios
      .put(url, {}, axiosConfig)
      .catch(function (error) {
        setPublishShiftsFinished(true);
        alert("An error occurred, publishing schedule was unsuccessful.");
      })
      .then(() => {
        fetch_data(
          [API_get_actual_schedule, API_get_published_shift_count],
          ["actual_schedule", "published_shift_count"],
          false
        );
        setPublishShiftsFinished(true);
      });
  }

  function autoSchedule(runType) {
    // if in daily view mode, lock all except day
    let newLockedDays = [...lockedDays];
    if (!weeklyView) {
      newLockedDays = newLockedDays.map((value, i) => {
        if (i === dowSelected) {
          return value;
        } else {
          return true;
        }
      });
    }

    const locked_data = {
      locked_days: newLockedDays,
      locked_people: lockedPeople,
    };
    let lock_type = lockAllShifts ? "locked" : "unlocked";
    if (runType === "autofill") {
      lock_type = "locked";
      runType = "autoschedule";
    }

    axios
      .put(
        API_auto_schedule +
          store_id +
          "&date_start=" +
          formatDate(week_start_date.toString()) +
          "&date_end=" +
          formatDate(addDays(week_start_date, 6).toString()) +
          "&run_type=" +
          runType +
          "&lock_type=" +
          lock_type,
        locked_data,
        axiosConfig
      )
      .catch(function (error) {
        setAutoScheduleError(true);
        setAutoScheduleFinished(true);
        alert(
          "An error occurred, please reach out to will@getrightwork.com and we will resolve the issue, or try again later."
        );
      })
      .then((res) => {
        set_schedule_differences(res.data);
        setAutoScheduleFinished(true);
      });
  }

  function acceptNewSchedule(days) {
    const accepted_dates = days
      .filter((day) => day.status === "accepted")
      .map((day) => day.date);
    let new_schedule = schedule_differences;
    new_schedule["accepted_dates"] = accepted_dates;
    new_schedule["new_schedule"] = new_schedule["new_schedule"].filter(
      (shift) => accepted_dates.includes(shift.date)
    );
    new_schedule["new_schedule_labor"] = new_schedule[
      "new_schedule_labor"
    ].filter((shift) => accepted_dates.includes(shift.date));
    console.log(new_schedule);
    axios
      .put(
        API_accept_auto_schedule +
          store_id +
          "&date_start=" +
          formatDate(week_start_date.toString()) +
          "&date_end=" +
          formatDate(addDays(week_start_date, 6).toString()),
        new_schedule,
        axiosConfig
      )
      .then((res) => {
        console.log("accepted new schedule");
        fetch_data(
          actual_schedule_api_calls,
          actual_schedule_set_data_names,
          false
        );
        setAcceptAutoScheduleFinished(true);
      });
    setLoadedFrom7Shifts(false);
    setLoadedFromTemplate(false);
  }

  function addForecastAdjustment(data, add_type) {
    console.log(data);
    setAddAdjustmentFinished(false);
    axios
      .put(
        API_add_forecast_adjustment +
          store_id +
          "&date_start=" +
          formatDate(week_start_date.toString()) +
          "&date_end=" +
          formatDate(addDays(week_start_date, 6).toString()) +
          "&add_type=" +
          add_type,
        data,
        axiosConfig
      )
      .then(() => {
        setAddAdjustmentFinished(true);
        fetch_data(all_api_calls, all_set_data_names, false);
      });
  }

  // function updatePersonInfo(data) {
  //   axios
  //     .put(API_update_team_member_info + store_id, data, axiosConfig)
  //     .then(() => {
  //       fetch_data(
  //         [
  //           API_get_store_team_members,
  //           API_get_default_target_hours,
  //           API_get_available_roles,
  //         ],
  //         ["store_team_members", "default_target_hours", "available_roles"],
  //         false
  //       );
  //     });
  // }

  const updatePersonInfo = async (data) => {
    try {
      toast.loading("Updating Employee Attributes...", { autoClose: false });

      const axiosConfig = getDefaultAxiosConfig();
      const res = await axios.put(
        API_update_team_member_info + store_id,
        data,
        axiosConfig
      );

      if (res.status === 200) {
        toast.dismiss();
        toast.success("Employee Attributes Updated!", { autoClose: 3000 });

        fetch_data(
          [
            API_get_store_team_members,
            API_get_default_target_hours,
            API_get_available_roles,
          ],
          ["store_team_members", "default_target_hours", "available_roles"],
          false
        );
      }
    } catch (error) {
      toast.dismiss();

      if (error.response?.data?.system?.message) {
        toast.error(error.response?.data?.system?.message, { autoClose: 3000 });
        console.log("ERROR MESSAGE", error.response?.data?.system);
      } else {
        toast.error("Request Failed", { autoClose: 3000 });
      }

      console.error("Error:", error); // Handle error as needed
    }
  };

  const post_missed_shift = async (shift_id, missed_shift_reason) => {
    // Prepare data to send
    const requestData = {
      store_id: props.homeStore,
      shift_id: shift_id,
      missed_shift_reason: missed_shift_reason,
    };

    try {
      toast.loading("Updating Missed Shift...", { autoClose: false });

      const res = await postRightworkAPI(API_post_missed_shift, requestData);

      if (res.status === 200) {
        toast.dismiss();
        toast.success("Missed Shift Updated", { autoClose: 3000 });

        fetch_data(
          [API_get_actual_schedule, API_get_published_shift_count],
          ["actual_schedule", "published_shift_count"],
          false
        );
      }
    } catch (error) {
      toast.dismiss();

      if (error.response?.data?.system?.message) {
        toast.error(error.response?.data?.system?.message, { autoClose: 3000 });
        console.log("Error Info", error.response?.data?.system?.data);
      } else {
        toast.error("Request Failed", { autoClose: 3000 });
      }

      console.error("Error:", error); // Handle error as needed
    }
  };

  const [copiedShift, setCopiedShift] = useState(null);

  async function get_data(api) {
    const url =
      api +
      store_id +
      "&date_start=" +
      formatDate(week_start_date.toString()) +
      "&date_end=" +
      formatDate(addDays(week_start_date, 6).toString());
    const res = await axios.get(url, axiosConfig);
    return res;
  }

  const default_api_calls = [
    API_get_forecasted_sales_daily,
    API_get_forecasted_transactions_daily,
    API_get_forecasted_other_daily,
    API_get_adjustments_chosen,
    API_get_forecasted_labor,
    API_get_forecasted_workload,
    API_get_recommended_schedule,
    API_get_recommended_schedule_labor_deployment,
    API_get_actual_sales_daily,
    API_get_actual_other_daily,
    API_get_calculated_earned_labor,
    API_get_actual_used_labor_vs_earned,
    API_get_recommended_used_labor_vs_earned,
    API_get_store_team_members,
    API_get_default_operating_hours,
    API_get_daily_operating_hours,
    API_get_role_types,
    API_get_weather,
    API_get_events,
    API_get_batch_guidance,
    API_get_intraday_metrics,
    API_get_local_events,
    API_get_all_time_off_requests,
    API_get_forecast_exclude_dates,
    API_get_spent_labor_daily_summaries,
    API_get_recent_avg_wages,
    API_list_frequent_schedules,
    API_get_grade_weights,
  ];
  const default_set_data_names = [
    "forecasted_sales_daily",
    "forecasted_transactions_daily",
    "forecasted_other_daily",
    "adjustments_chosen",
    "forecasted_labor",
    "forecasted_workload",
    "recommended_schedule",
    "recommended_schedule_labor_deployment",
    "actual_sales_daily",
    "actual_other_daily",
    "calculated_earned_labor",
    "actual_used_labor_vs_earned",
    "recommended_used_labor_vs_earned",
    "store_team_members",
    "default_operating_hours",
    "daily_operating_hours",
    "role_types",
    "weather",
    "events",
    "batch_guidance",
    "intraday_metrics",
    "local_events",
    "all_time_off_requests",
    "forecast_exclude_dates",
    "get_spent_labor_daily_summaries",
    "recent_avg_wages",
    "frequent_schedules",
    "grade_weights",
  ];

  const actual_schedule_api_calls = [
    API_get_actual_schedule,
    API_get_actual_schedule_labor_deployment,
    API_get_published_shift_count,
    API_get_intraday_metrics,
  ];
  const actual_schedule_set_data_names = [
    "actual_schedule",
    "actual_schedule_labor_deployment",
    "published_shift_count",
    "intraday_metrics",
  ];

  const team_member_api_calls = [
    API_get_default_availability,
    API_get_default_target_hours,
    API_get_weekly_target_hours,
    API_get_request_off,
    API_get_available_roles,
    API_get_role_names,
    API_get_all_time_off_requests,
    // API_get_station_info,
  ];
  const team_member_set_data_names = [
    "default_availability",
    "default_target_hours",
    "weekly_target_hours",
    "request_off",
    "available_roles",
    "role_names",
    "all_time_off_requests",
    // "station_info",
  ];

  const data_names_with_role_type = [
    "forecasted_labor",
    "forecasted_workload",
    "recommended_schedule",
    "recommended_schedule_labor_deployment",
    "actual_schedule",
    "actual_schedule_labor_deployment",
    "calculated_earned_labor",
    "actual_used_labor_vs_earned",
    "recommended_used_labor_vs_earned",
    "get_spent_labor_daily_summaries",
    "recent_avg_wages",
  ];

  const all_api_calls = default_api_calls.concat(
    actual_schedule_api_calls.concat(team_member_api_calls)
  );
  const all_set_data_names = default_set_data_names.concat(
    actual_schedule_set_data_names.concat(team_member_set_data_names)
  );

  const filter_data_by_role_type = (
    data,
    role_types,
    roleTypeOptions,
    data_names
  ) => {
    var new_data = { ...data };
    if (role_types.length < roleTypeOptions.length) {
      data_names.map((d_name) => {
        if (new_data[d_name]) {
          new_data[d_name] = new_data[d_name].filter((d) =>
            role_types.includes(d.role_type)
          );
        }
      });
    }
    set_all_data(new_data);
  };

  function arraysAreEqual(arr1, arr2) {
    if (arr1.length !== arr2.length) {
      return false;
    }
    for (let i = 0; i < arr1.length; i++) {
      if (arr1[i] !== arr2[i]) {
        return false;
      }
    }
    return true;
  }

  const fetch_data = async (api_calls, set_data_names, load_screen) => {
    try {
      setLoading(load_screen);
      const res = await Promise.all(api_calls.map((api) => get_data(api)));
      var data = { ...all_data_full };
      console.log(set_data_names);
      res.map((res, i) => (data[set_data_names[i]] = res.data));
      set_all_data_full(data);

      const newRoleTypeOptions = data.role_types.map(function (i) {
        return i.role_type;
      });
      if (
        roleType.length === 0 ||
        !arraysAreEqual(newRoleTypeOptions, roleTypeOptions)
      ) {
        setRoleType(newRoleTypeOptions);
        setRoleTypeOptions(newRoleTypeOptions);
        filter_data_by_role_type(
          data,
          newRoleTypeOptions,
          newRoleTypeOptions,
          data_names_with_role_type
        );
      } else {
        filter_data_by_role_type(
          data,
          roleType,
          roleTypeOptions,
          data_names_with_role_type
        );
      }
      setLoading(false);
    } catch {
      throw Error("Promise failed");
    }
  };

  // Similar to componentDidMount and componentDidUpdate:

  useEffect(() => {
    filter_data_by_role_type(
      all_data_full,
      roleType,
      roleTypeOptions,
      data_names_with_role_type
    );
  }, [roleType]);

  useEffect(() => {
    if (typeof store_id !== "undefined") {
      fetch_data(all_api_calls, all_set_data_names, true);
      setLockedDays([false, false, false, false, false, false, false]);
    }
  }, [week_start_date, store_id]);

  useEffect(() => {
    if (typeof store_id !== "undefined") {
      loadTemplateNames();
      loadHistoricalSalesContext();
    }
  }, [store_id, week_start_date]);

  function increment_week(start_date, add_week) {
    const new_start_date = addDays(start_date, add_week * 7);
    set_week_start_date(new_start_date);
  }

  const scheduled_hours = all_data.actual_schedule_labor_deployment;

  const [store_team_member_weekly_info, set_store_team_member_weekly_info] =
    React.useState([]);
  const [
    store_team_member_weekly_info_view,
    set_store_team_member_weekly_info_view,
  ] = React.useState([]);

  function agg_column(df, column) {
    return df?.reduce(function (a, b) {
      return a + b[column];
    }, 0);
  }

  function filter_df_to_day(df, column, date_str) {
    return df?.filter((s) => s[column] === date_str);
  }

  function countConflicts(shift, avail, timeoff) {
    let countOfConflicts = 0;

    if (shift.length > 0) {
      if (timeoff.length > 0) {
        countOfConflicts += 1; // Conflict if time off is requested
      } else if (avail.length > 0) {
        const { available_from, available_to, available_from_2, available_to_2 } = avail[0];

        // If the shift falls outside both availability ranges
        const outsideRange1 =
          available_from !== 0 || available_to !== 24
            ? available_from > shift[0].shift_start || available_to < shift[0].shift_end
            : false;
        const outsideRange2 =
          available_from_2 !== undefined && available_to_2 !== undefined // Ensure availability_2 exists
            ? available_from_2 > shift[0].shift_start || available_to_2 < shift[0].shift_end
            : false;

        // Increment conflict count if shift is outside both ranges
        if (outsideRange1 && outsideRange2) {
          countOfConflicts += 1;
        }
      }
    }

    return countOfConflicts;
  }


  function process_data() {
    if (all_data.store_team_members.length > 0) {
      // aggregate weekly hours by person
      let weekly_hours_by_person = all_data.actual_schedule.reduce(function (
        res,
        value
      ) {
        if (!res[value.store_team_member]) {
          res[value.store_team_member] = 0;
        }
        res[value.store_team_member] += value.hours;
        return res;
      },
      {});

      let lockedTeamDict = {};
      // add total weekly hours to store_team_member
      var store_team_member_weekly_info = all_data.store_team_members.map(
        (x) => {
          x.hours = weekly_hours_by_person[x.id]
            ? weekly_hours_by_person[x.id]
            : 0;
          if (all_data.default_target_hours.length > 0) {
            const target_hours = all_data.default_target_hours.filter(
              (p) => p.store_team_member === x.id
            );
            x.min_weekly_hours = agg_column(target_hours, "min_weekly_hours");
            x.target_weekly_hours = agg_column(
              target_hours,
              "target_weekly_hours"
            );
            x.max_weekly_hours = agg_column(target_hours, "max_weekly_hours");
          } else {
            x.min_weekly_hours = 0;
            x.target_weekly_hours = 0;
            x.max_weekly_hours = 0;
          }
          if (all_data.default_availability.length > 0) {
            const available_hours_default = all_data.default_availability
              .filter((p) => p.store_team_member === x.id)
              .map((d) => {
                d.duration = d.available_to - d.available_from;
                return d;
              });
            x.default_available_hours = agg_column(
              available_hours_default,
              "duration"
            );
          } else {
            x.default_available_hours = 0;
          }
          x.total_available_hours = x.default_available_hours;
          x.available_role_info = all_data.available_roles.filter(
            (p) => p.store_team_member === x.id
          );
          let main_role_name = ""; //all_data.role_names[0]["role_name"];
          let role_type = ""; //all_data.role_names[0]["role_type"];
          let main_role_id = null; //all_data.role_names[0]["id"];
          let mainRole = x.available_role_info.find(
            (r) => r.main_role_ind === 1
          );
          if (mainRole) {
            const roleNameInfo = all_data.role_names.find(
              (r) => r.id === mainRole.scheduled_role
            );
            if (roleNameInfo) {
              main_role_name = roleNameInfo.role_name;
              main_role_id = roleNameInfo.id;
            }
            role_type = getRoleType(
              mainRole.scheduled_role,
              all_data.role_names
            );
          }
          x.role = main_role_name;
          x.role_type = role_type;
          x.main_role_id = main_role_id;
          x.countOfConflicts = 0;
          const person_time_off_requests =
            all_data.all_time_off_requests.filter(
              (p) => p.store_team_member === x.id
            );
          [0, 1, 2, 3, 4, 5, 6].map((dow) => {
            const date = addDays(week_start_date, dow);
            const date_str = formatDate(date);
            const shift = filter_df_to_day(
              all_data.actual_schedule,
              "date",
              date_str
            ).filter((s) => s.store_team_member === x.id);

            // Get the day of week for week_start_date (0 for Sunday, 1 for Monday, etc.)
            const startDayOfWeek = props.businessWeekStartDayIndex;

            // Calculate the adjusted day of the week for default_availability
            let adjustedDow;
            if (startDayOfWeek === 0) {
              // If the week starts on Sunday
              adjustedDow = dow === 0 ? 7 : dow;
            } else {
              // For any other day
              adjustedDow = (dow + startDayOfWeek) % 7;
              adjustedDow = adjustedDow === 0 ? 7 : adjustedDow; // Adjust for Sunday
            }

            let default_availability = filter_df_to_day(
              all_data.default_availability,
              "dow",
              adjustedDow
            ).filter((s) => s.store_team_member === x.id);

            let dow_avail = default_availability;

            let timeoff = person_time_off_requests.filter((request) => {
              // If date_upper_bound is null, then check if date_lower_bound matches the specific date
              if (!request.date_upper_bound) {
                return request.date_lower_bound === date_str;
              }

              // If date_upper_bound is not null, then check if specific date is within the range of date_lower_bound to date_upper_bound
              return (
                request.date_lower_bound <= date_str &&
                request.date_upper_bound >= date_str
              );
            });

            x[dow] = shift;
            x[dow + "_availability"] = dow_avail;
            x[dow + "_timeoff"] = timeoff;
            x.countOfConflicts += countConflicts(shift, dow_avail, timeoff);
          });
          x.overtimeFlag = x.hours > 40 ? 1 : 0;

          if (x.id in lockedPeople) {
            lockedTeamDict[x.id] = lockedPeople[x.id];
          } else {
            lockedTeamDict[x.id] = false;
          }
          return x;
        }
      );
      let new_view = filter_data(
        filtered,
        searchInput,
        store_team_member_weekly_info
      );
      if (roleType.length < roleTypeOptions.length) {
        new_view = new_view.filter((x) => roleType.includes(x.role_type));
      }

      setLockedPeople(lockedTeamDict);
      set_store_team_member_weekly_info(store_team_member_weekly_info);
      if (showWarnings) {
        set_store_team_member_weekly_info_view(
          sort_data("warnings", new_view, dowSelected)
        );
      } else {
        set_store_team_member_weekly_info_view(
          sort_data(sorted, new_view, dowSelected)
        );
      }
    }
  }

  function filter_data(filtered, searchInput, old_data) {
    const data = [...old_data];
    const searchTerm = searchInput.toLowerCase();

    if (filtered === "") {
      const filteredData = data.filter((d) => {
        const full_name = `${d.first_name} ${d.last_name}`.toLowerCase();
        return full_name.includes(searchTerm);
      });

      if (filteredData.length > 0) {
        setNoMatchingSearch(false);
      } else {
        setNoMatchingSearch(true);
      }

      return filteredData.length > 0 ? filteredData : data;
    } else {
      let filteredData = data;

      if (filtered === "Scheduled Only") {
        filteredData = filteredData.filter((d) => d.hours > 0);
      } else if (filtered === "has_shift") {
        filteredData = filteredData.filter((d) => d[dowSelected].length > 0);
      } else {
        filteredData = filteredData.filter((d) => d.total_available_hours > 0);
      }

      let filteredData2 = filteredData.filter((d) => {
        const full_name = `${d.first_name} ${d.last_name}`.toLowerCase();
        return full_name.includes(searchTerm);
      });

      if (filteredData2.length > 0) {
        setNoMatchingSearch(false);
      } else {
        setNoMatchingSearch(true);
      }
      return filteredData2.length > 0 ? filteredData2 : filteredData;
    }
  }
  function sort_data(sorted, old_data, dow) {
    const data = [...old_data];
    if (sorted === "warnings") {
      return data.sort((a, b) => {
        const aWarnings = a.overtimeFlag * 5 + a.countOfConflicts;
        const bWarnings = b.overtimeFlag * 5 + b.countOfConflicts;

        if (aWarnings > bWarnings) {
          return -1;
        } else if (bWarnings > aWarnings) {
          return 1;
        } else {
          // When warnings are equal, sort by first_name
          return a.first_name?.localeCompare(b.first_name);
        }
      });
    } else if (sorted === "") {
      return data.sort((a, b) => {
        // First, compare by sort_index
        if (a.sort_index < b.sort_index) return -1;
        if (a.sort_index > b.sort_index) return 1;

        // If sort_index is equal, then compare by role_type
        let dept_a = a.primary_role_type_name ? a.primary_role_type_name : "ZZ";
        let dept_b = b.primary_role_type_name ? b.primary_role_type_name : "ZZ";
        if (dept_a < dept_b) return -1;
        if (dept_a > dept_b) return 1;

        let role_a = a.role === "" ? "ZZ" : a.role;
        let role_b = b.role === "" ? "ZZ" : b.role;
        if (role_a < role_b) return -1;
        if (role_a > role_b) return 1;

        // If role_type is also equal, finally compare by first_name
        return a.last_name?.localeCompare(b.last_name);
      });
    } else if (sorted === "availability") {
      return data.sort((a, b) =>
        a.total_available_hours < b.total_available_hours
          ? 1
          : b.total_available_hours < a.total_available_hours
          ? -1
          : 0
      );
    } else if (sorted === "department") {
      return data.sort((a, b) => {
        // If sort_index is equal, then compare by role_type
        let dept_a = a.primary_role_type_name ? a.primary_role_type_name : "ZZ";
        let dept_b = b.primary_role_type_name ? b.primary_role_type_name : "ZZ";
        if (dept_a < dept_b) return -1;
        if (dept_a > dept_b) return 1;
        return a.first_name?.localeCompare(b.first_name);
      });
    } else if (sorted === "first") {
      return data.sort((a, b) => a.first_name?.localeCompare(b.first_name));
    } else if (sorted === "last") {
      return data.sort((a, b) => a.last_name?.localeCompare(b.last_name));
    } else if (sorted === "shift_start") {
      return data.sort((a, b) =>
        a[dow].length > 0
          ? b[dow].length > 0
            ? a[dow][0].shift_start < b[dow][0].shift_start
              ? -1
              : a[dow][0].shift_start > b[dow][0].shift_start
              ? 1
              : a[dow][0].shift_end < b[dow][0].shift_end
              ? -1
              : a[dow][0].shift_end > b[dow][0].shift_end
              ? 1
              : 0
            : -1
          : 1
      );
    } else {
      return data.sort((a, b) =>
        a[sorted] < b[sorted] ? 1 : b[sorted] < a[sorted] ? -1 : 0
      );
    }
  }

  useEffect(() => {
    const new_data = filter_data(
      filtered,
      searchInput,
      store_team_member_weekly_info
    );
    set_store_team_member_weekly_info_view(
      sort_data(sorted, new_data, dowSelected)
    );
  }, [filtered, searchInput]);

  useEffect(() => {
    const new_data = sort_data(
      sorted,
      store_team_member_weekly_info_view,
      dowSelected
    );
    set_store_team_member_weekly_info_view(new_data);
  }, [sorted, dowSelected]);

  useEffect(() => {
    if (showWarnings) {
      set_store_team_member_weekly_info_view(
        sort_data("warnings", store_team_member_weekly_info_view, dowSelected)
      );
    } else {
      set_store_team_member_weekly_info_view(
        sort_data(sorted, store_team_member_weekly_info_view, dowSelected)
      );
    }
  }, [showWarnings, dowSelected]);

  useEffect(() => {
    process_data();
  }, [all_data]);

  function checkIfForecastAvail() {
    try {
      return all_data.forecasted_labor.length > 0;
    } catch (error) {
      return false;
    }
  }

  function countWarnings() {
    let countConflicts = 0;
    let countOvertime = 0;
    if (all_data.store_team_members.length > 0) {
      store_team_member_weekly_info.map((value, index) => {
        countOvertime += value.overtimeFlag;
        countConflicts += value.countOfConflicts;
      });
    }
    return { countOfConflicts: countConflicts, countOfOvertime: countOvertime };
  }

  console.log("AVAILABILITY NEW INFO", store_team_member_weekly_info)

  function countUnpublished() {
    let count = 0;

    if (all_data.actual_schedule) {
      if (
        all_data.actual_schedule?.length <
        all_data.published_shift_count?.count_of_published_shifts
      ) {
        count =
          all_data.published_shift_count?.count_of_published_shifts -
          all_data.actual_schedule?.length;
      }
      all_data.actual_schedule.map((value, index) => {
        if (value.is_publishable === true) {
          count += 1;
        }
      });
    }
    return count;
  }

  let countOfWarnings = countWarnings();
  let countOfUnpublished = countUnpublished();

  const week_already_started =
    new Date(week_start_date.toDateString()) <=
    new Date(new Date().toDateString());

  function getAverageWage(data) {
    if (data?.length === 0) {
      return 0;
    }

    let totalWage = 0;

    for (let i = 0; i < data?.length; i++) {
      totalWage += data?.[i].average_wage;
    }

    const averageWage = totalWage / data?.length;

    return averageWage;
  }

  const averageWageFromPreviousWeek = getAverageWage(
    all_data?.recent_avg_wages
  );

  return (
    <div>
      {weeklyView ? (
        <div>
          <TopBarDisplay
            start_date={week_start_date}
            end_date={addDays(week_start_date, 6)}
            increment_week={increment_week}
            roleType={roleType}
            setRoleType={setRoleType}
            storeOptions={props.storeOptions}
            homeStore={props.homeStore}
            storeNames={props.storeNames}
            change_home_store={props.change_home_store}
            roleTypeOptions={roleTypeOptions}
            store_team_member_weekly_info={store_team_member_weekly_info}
            store_team_member_weekly_info_view={
              store_team_member_weekly_info_view
            }
            countOfWarnings={countOfWarnings}
            showWarnings={showWarnings}
            setShowWarnings={setShowWarnings}
            set_week_start_date={set_week_start_date}
            businessWeekStartDayIndex={props.businessWeekStartDayIndex}
            toggleDailyWeekly={toggleDailyWeekly}
            windowWidth={props.windowWidth}
          />
        </div>
      ) : (
        <div>
          <DailyTopBarDisplay
            date={date}
            increment_day={increment_day}
            start_date={week_start_date}
            end_date={addDays(week_start_date, 6)}
            increment_week={increment_week}
            roleType={roleType}
            setRoleType={setRoleType}
            storeOptions={props.storeOptions}
            homeStore={props.homeStore}
            storeNames={props.storeNames}
            change_home_store={props.change_home_store}
            roleTypeOptions={roleTypeOptions}
            store_team_member_weekly_info={store_team_member_weekly_info}
            store_team_member_weekly_info_view={
              store_team_member_weekly_info_view
            }
            countOfWarnings={countOfWarnings}
            showWarnings={showWarnings}
            setShowWarnings={setShowWarnings}
            set_week_start_date={set_week_start_date}
            businessWeekStartDayIndex={props.businessWeekStartDayIndex}
            toggleDailyWeekly={toggleDailyWeekly}
          />
        </div>
      )}
      <div style={{ textAlign: "left", marginTop: "20px", width: "100%" }}>
        <ActionBarDisplay
          store_team_member_weekly_info_view={
            store_team_member_weekly_info_view
          }
          autosched_available={checkIfForecastAvail()}
          lockAllShifts={lockAllShifts}
          setLockAllShifts={toggleLockAllShifts}
          get_t_str={get_t_str}
          week_already_started={week_already_started}
          week_ended={
            new Date(addDays(week_start_date, 6).toDateString()) <=
            new Date(new Date().toDateString())
          }
          addDays={addDays}
          loadFrom7Shift={loadFrom7Shifts}
          toggleDisplayMode={toggleDisplayMode}
          displayMode={displayMode}
          toggleMinimizeMode={toggleMinimizeMode}
          minimizeMode={minimizeMode}
          saveScheduleAsTemplate={saveScheduleAsTemplate}
          deleteScheduleTemplate={deleteScheduleTemplate}
          week_start_date={week_start_date}
          load7ShiftsScheduleFinished={load7ShiftsScheduleFinished}
          setLoad7ShiftsScheduleFinished={setLoad7ShiftsScheduleFinished}
          template_names={template_names}
          formatDate={formatDate}
          loadFromTemplate={loadFromTemplate}
          loadFromTemplateFinished={loadFromTemplateFinished}
          setLoadFromTemplateFinished={setLoadFromTemplateFinished}
          filterTeam={filterTeam}
          filtered={filtered}
          sortTeam={sortTeam}
          sorted={sorted}
          setSorted={setSorted}
          autoSchedule={autoSchedule}
          acceptNewSchedule={acceptNewSchedule}
          schedule_differences={schedule_differences}
          autoScheduleFinished={autoScheduleFinished}
          autoScheduleError={autoScheduleError}
          setAutoScheduleError={setAutoScheduleError}
          setAutoScheduleFinished={setAutoScheduleFinished}
          acceptAutoScheduleFinished={acceptAutoScheduleFinished}
          setAcceptAutoScheduleFinished={setAcceptAutoScheduleFinished}
          deleteAllShifts={deleteAllShifts}
          scheduled_hours={scheduled_hours}
          loadedFrom7Shifts={loadedFrom7Shifts}
          loadedFromTemplate={loadedFromTemplate}
          loadedFromAuto={loadedFromAuto}
          publishShifts={publishShifts}
          roleType={roleType}
          roleTypeOptions={roleTypeOptions}
          publishShiftsFinished={publishShiftsFinished}
          setPublishShiftsFinished={setPublishShiftsFinished}
          pushedChanges={pushedChanges}
          weeklyView={weeklyView}
          store_team_member_weekly_info={store_team_member_weekly_info}
          actual_schedule={all_data.actual_schedule}
          has7ShiftsIntegration={props.has7ShiftsIntegration}
          role_types={all_data.role_types}
          countOfWarnings={countOfWarnings}
          countOfUnpublished={countOfUnpublished}
          countOfPublished={
            all_data.published_shift_count?.count_of_published_shifts
          }
          role_names={all_data.role_names}
          showUnpublishedShifts={showUnpublishedShifts}
          setShowUnpublishedShifts={setShowUnpublishedShifts}
          notes={all_data.events}
        />
      </div>
      <div
        style={{ textAlign: "left", marginTop: "20px", paddingBottom: "40px" }}
      >
        <ScheduleDisplay
          store_team_member_weekly_info={store_team_member_weekly_info}
          store_team_member_weekly_info_view={
            store_team_member_weekly_info_view
          }
          lockAllShifts={lockAllShifts}
          get_t_str={get_t_str}
          filter_df_to_day={filter_df_to_day}
          agg_column={agg_column}
          formatDate={formatDate}
          all_data={all_data}
          start_date={week_start_date}
          week_in_past={
            new Date(addDays(week_start_date, 6).toDateString()) <
            new Date(new Date().toDateString())
          }
          week_already_started={week_already_started}
          addShift={addShift}
          editAvailability={editAvailability}
          updatePersonInfo={updatePersonInfo}
          displayMode={displayMode}
          minimizeMode={minimizeMode}
          store_id={store_id}
          addDays={addDays}
          sorted={sorted}
          filtered={filtered}
          setSearchInput={setSearchInput}
          noMatchingSearch={noMatchingSearch}
          loading={loading}
          lockedDays={lockedDays}
          lock_day={lock_day}
          lockedPeople={lockedPeople}
          setLockedPeople={setLockedPeople}
          lock_person={lock_person}
          saveShiftFinished={saveShiftFinished}
          setSaveShiftFinished={setSaveShiftFinished}
          saveAvailabilityFinished={saveAvailabilityFinished}
          setSaveAvailabilityFinished={setSaveAvailabilityFinished}
          addForecastAdjustment={addForecastAdjustment}
          addAdjustmentFinished={addAdjustmentFinished}
          setCopiedShift={setCopiedShift}
          copiedShift={copiedShift}
          addMultipleShifts={addMultipleShifts}
          toggleDailyWeekly={toggleDailyWeekly}
          weeklyView={weeklyView}
          dowSelected={dowSelected}
          edit_hoo={edit_hoo}
          saveEvent={saveEvent}
          deleteEvent={deleteEvent}
          saveEventFinished={saveEventFinished}
          setSaveEventFinished={setSaveEventFinished}
          saveForecastExcludeDate={saveForecastExcludeDate}
          deleteForecastExcludeDate={deleteForecastExcludeDate}
          businessWeekStartDayIndex={props.businessWeekStartDayIndex}
          start_qhnum={props.start_qhnum}
          loadEmployeePreviousSchedule={loadEmployeePreviousSchedule}
          showLaborPercent={showLaborPercent}
          averageWageFromPreviousWeek={averageWageFromPreviousWeek}
          laborPercentData={all_data?.get_spent_labor_daily_summaries}
          frequentShifts={all_data.frequent_schedules?.redux?.payload.map}
          roleType={roleType}
          setRoleType={setRoleType}
          roleTypeOptions={roleTypeOptions}
          historicalSalesContext={
            historical_sales_context?.redux?.payload?.data
          }
          post_missed_shift={post_missed_shift}
          breakWaiverEnabled={props.breakWaiverEnabled}
          showUnpublishedShifts={showUnpublishedShifts}
          request_time_off={request_time_off}
          defaultBreakLengthHours={props.defaultBreakLengthHours}
          windowWidth={props.windowWidth}
          tplhEnabled={props.tplhEnabled}
        />
      </div>
    </div>
  );
}

export default HomeDisplay;
