import React, { useState, useEffect } from "react";
import DailyTopBarDisplay from "./daily_top_bar_components/DailyTopBarDisplay";
import DailyPlanInfo from "./plan_contents/DailyPlanInfo";
import DailyPlanInfo2 from "./plan_contents/DailyPlanInfo2";
import axios from "axios";
import {
  API_get_actual_schedule,
  API_get_store_team_members,
  API_get_deployment_roles,
  API_no_shows,
  API_dayparts,
  API_role_assignments,
  API_get_role_types,
  API_save_no_show,
  API_update_role_assignment,
  API_delete_role_assignment,
  API_save_dayparts,
  API_delete_daypart,
  API_auto_assign_roles,
} from "../../../constants";
import LoadSpinner from "../../../utilities/LoadSpinner";
import { getDefaultAxiosConfig } from "../../../utilities/requests";

const axiosConfig = getDefaultAxiosConfig();

function DailyPlanDisplay(props) {
  const [date, setDate] = useState(new Date());
  const [roleType, setRoleType] = React.useState([]);
  const [roleTypeOptions, setRoleTypeOptions] = React.useState([]);
  const [scheduledEmployees, setScheduledEmployees] = useState([]);
  const [storeTeamMembers, setStoreTeamMembers] = useState([]);
  const [deploymentRoles, setDeploymentRoles] = useState([]);
  const [noShows, setNoShows] = useState([]);
  const [dayparts, setDayparts] = useState([]);
  const [roleAssignments, setRoleAssignments] = useState([]);
  const [roleTypes, setRoleTypes] = useState([]);
  // Add a new state variable to track if data has been loaded
  const [dataLoaded, setDataLoaded] = useState(false);

  function formatDate(date) {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  }

  function addDays(theDate, days) {
    return new Date(theDate.getTime() + days * 24 * 60 * 60 * 1000);
  }

  function increment_day(date, days) {
    const new_date = addDays(date, days);
    setDate(new_date);
  }

  const saveNoShow = async (employeeId, isNoShow) => {
    try {
      const response = await axios.post(
        API_save_no_show,
        {
          store_team_member_id: employeeId,
          no_show_date: formatDate(date.toString()),
          is_no_show: isNoShow,
        },
        axiosConfig
      );

      if (response.status === 200) {
        console.log("No-show saved successfully.");
        fetchNoShows();
      } else {
        console.log("Error saving no-show.");
        fetchNoShows();
      }
    } catch (error) {
      console.log("Error saving no-show: ", error);
    }
  };

  const updateRoleAssignment = async (
    daypartId,
    storeTeamMemberId,
    deploymentRoleId
  ) => {
    try {
      const response = await axios.post(
        API_update_role_assignment,
        {
          daypart_id: daypartId,
          store_team_member_id: storeTeamMemberId,
          deployment_role_id: deploymentRoleId,
          assigned_date: formatDate(date.toString()),
        },
        axiosConfig
      );

      if (response.status === 200) {
        console.log("Assignment saved successfully.");
        fetchRoleAssignments();
      } else {
        console.log("Error saving assignment.");
        fetchRoleAssignments();
      }
    } catch (error) {
      console.error("Error updating role assignment:", error);
    }
  };

  const deleteRoleAssignment = async (assignmentId) => {
    try {
      const response = await axios.delete(
        `${API_delete_role_assignment}?assignment_id=${assignmentId}`,
        axiosConfig
      );
      fetchRoleAssignments();
      return response.data;
    } catch (error) {
      console.error(`Error deleting role assignment: ${error.message}`);
    }
  };

  const saveDayparts = async (dayparts) => {
    try {
      const response = await axios.post(
        `${API_save_dayparts}?store_id=${props.homeStore}`,
        dayparts,
        axiosConfig
      );
      fetchDayparts();
      console.log("New dayparts saved:", response.data);
    } catch (error) {
      console.error("Error saving new dayparts:", error);
    }
  };

  const deleteDaypart = async (daypartId) => {
    try {
      await axios.delete(
        `${API_delete_daypart}?daypart_id=${daypartId}`,
        axiosConfig
      );
    } catch (error) {
      console.error("Error deleting daypart:", error);
    }
  };

  const autoAssignRoles = async (daypartId, unassignedPeople) => {
    const data = {
      unassignedPeople: unassignedPeople,
      deploymentRoles: deploymentRoles,
    };
    try {
      await axios.post(
        `${API_auto_assign_roles}?store_id=${
          props.homeStore
        }&daypart_id=${daypartId}&date=${formatDate(date.toString())}`,
        data,
        axiosConfig
      );
      fetchRoleAssignments();
    } catch (error) {
      console.error("Error auto-assigning roles:", error);
    }
  };

  useEffect(() => {
    setDataLoaded(false);
  }, [props.homeStore]);

  useEffect(() => {
    async function fetchData() {
      await fetchScheduledEmployees();
      await fetchStoreTeamMembers();
      await fetchDeploymentRoles();
      await fetchNoShows();
      await fetchDayparts();
      await fetchRoleAssignments();
      await fetchRoleTypes();

      // Set dataLoaded to true once all the data has been fetched
      setDataLoaded(true);
    }

    fetchData();
  }, [date, props.homeStore]);

  const fetchNoShows = async () => {
    const response = await axios.get(
      `${API_no_shows}store_id=${props.homeStore}&date=${formatDate(
        date.toString()
      )}`,
      axiosConfig
    );
    setNoShows(response.data);
  };

  const fetchDayparts = async () => {
    const response = await axios.get(
      `${API_dayparts}store_id=${props.homeStore}&date=${formatDate(
        date.toString()
      )}`,
      axiosConfig
    );
    setDayparts(response.data);
  };

  const fetchRoleAssignments = async () => {
    const response = await axios.get(
      `${API_role_assignments}store_id=${props.homeStore}&date=${formatDate(
        date.toString()
      )}`,
      axiosConfig
    );
    setRoleAssignments(response.data);
  };

  const fetchScheduledEmployees = async () => {
    const response = await axios.get(
      `${API_get_actual_schedule}${props.homeStore}&date_start=${formatDate(
        date.toString()
      )}&date_end=${formatDate(date.toString())}`,
      axiosConfig
    );
    setScheduledEmployees(response.data);
  };

  const fetchStoreTeamMembers = async () => {
    const response = await axios.get(
      `${API_get_store_team_members}${props.homeStore}`,
      axiosConfig
    );
    setStoreTeamMembers(response.data);
  };

  const fetchDeploymentRoles = async () => {
    const response = await axios.get(
      `${API_get_deployment_roles}${props.homeStore}`,
      axiosConfig
    );
    setDeploymentRoles(response.data);
  };

  const fetchRoleTypes = async () => {
    const response = await axios.get(
      `${API_get_role_types}${props.homeStore}`,
      axiosConfig
    );
    setRoleTypes(response.data);
    const newRoleTypeOptions = response.data.map(function (i) {
      return i.role_type;
    });
    setRoleTypeOptions(newRoleTypeOptions);
    if (roleType.length === 0) {
      setRoleType(newRoleTypeOptions);
    }
  };

  const joinData = (scheduledEmployees, storeTeamMembers) => {
    return scheduledEmployees.map((employee) => {
      const teamMember = storeTeamMembers.find(
        (member) => member.scheduling_app_user_id === employee.person
      );
      return {
        ...employee,
        name: teamMember?.first_name + " " + teamMember?.last_name,
        store_team_member_id: teamMember?.id,
      };
    });
  };

  const getRolesByType = (typeId) => {
    return deploymentRoles.filter((role) => role.role_type_id === typeId);
  };

  const combinedData = joinData(scheduledEmployees, storeTeamMembers);

  const filterByRoleType = (data, roleTypeArray) => {
    return data.filter((item) => roleTypeArray.includes(item.role_type));
  };

  const filteredCombinedData = filterByRoleType(combinedData, roleType);
  const filteredDeploymentRoles = filterByRoleType(deploymentRoles, roleType);
  const filteredRoleTypes = filterByRoleType(roleTypes, roleType);

  return (
    <div className="mx-10 my-5">
      <DailyTopBarDisplay
        date={date}
        increment_day={increment_day}
        roleType={roleType}
        setRoleType={setRoleType}
        storeOptions={props.storeOptions}
        homeStore={props.homeStore}
        storeNames={props.storeNames}
        change_home_store={props.change_home_store}
        roleTypeOptions={roleTypeOptions}
        view="daily_plan"
      />
      {dataLoaded ? (
        <div className="mt-10">
          <DailyPlanInfo2
            combinedData={filteredCombinedData}
            deploymentRoles={filteredDeploymentRoles}
            getRolesByType={getRolesByType}
            roleTypes={filteredRoleTypes}
            noShows={noShows}
            dayparts={dayparts}
            roleAssignments={roleAssignments}
            saveNoShow={saveNoShow}
            updateRoleAssignment={updateRoleAssignment}
            deleteRoleAssignment={deleteRoleAssignment}
            saveDayparts={saveDayparts}
            deleteDaypart={deleteDaypart}
            autoAssignRoles={autoAssignRoles}
          />
        </div>
      ) : (
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <LoadSpinner />
        </div>
      )}
    </div>
  );
}

export default DailyPlanDisplay;
