import React, { useEffect } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Drawer from "@mui/material/Drawer";
import AddTaskIcon from "@mui/icons-material/AddTask";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import RadioGroup from "@mui/material/RadioGroup";
import Radio from "@mui/material/Radio";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import SaveIcon from "@mui/icons-material/Save";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import AvailabilityCardModalDisplay from "./AvailabilityCardModalDisplay";
import LoadingButton from "@mui/lab/LoadingButton";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";
import ScheduleIcon from "@mui/icons-material/Schedule";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import ButtonGroup from "@mui/material/ButtonGroup";
import {
  text_to_num,
  createDayArray,
  get_t_str,
  generateTimeOptions,
  filterOptions,
} from "../../../../helpers/helpers";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import DrawerHeader from "../../requests/shared/helpers/DrawerHeader";
import DrawerFooterContainerSmall from "../../requests/shared/helpers/DrawerFooterContainerSmall";
import Autocomplete from "@mui/material/Autocomplete";
import TabsTwo from "../../requests/shared/helpers/TabsTwo";
import { PurpleSwitch } from "../../../../helpers/helpers";
import DatePickerDropdown from "../../requests/shared/helpers/DatePickerDropdown";

// Creating a custom theme using createTheme from MUI
const theme = createTheme({
  status: {
    danger: "#e53e3e",
  },
  palette: {
    primary: {
      main: "#0971f1",
      darker: "#053e85",
    },
    neutral: {
      main: "#64748B",
      contrastText: "#fff",
    },
  },
});

function AvailabilityInputsModal(props) {
  const [state, setState] = React.useState(props.availability_info);

  console.log("AVAILABILITY", state)
  const [value, setValue] = React.useState(
    getRadioValue(props.availability_info)
  );
  const [default_checked, set_default_checked] = React.useState(
    props.availability_info.default
  );
  const [savingLoading, setSavingLoading] = React.useState(false);
  const [availabilityInfoString, setAvailabilityInfoString] = React.useState({
    available_from: props.get_t_str(props.availability_info.available_from),
    available_to: props.get_t_str(props.availability_info.available_to),
    available_from_2: props.get_t_str(props.availability_info.available_from_2),
    available_to_2: props.get_t_str(props.availability_info.available_to_2),
    preferred_from: props.get_t_str(props.availability_info.preferred_from),
    preferred_to: props.get_t_str(props.availability_info.preferred_to),
  });
  const [appliedTo, setAppliedTo] = React.useState([
    false,
    false,
    false,
    false,
    false,
    false,
    false,
  ]);

  const [enterSplitAvailability, setEnterSplitAvailability] =
    React.useState(props.availability_info.available_to_2);

  const [enterPreferences, setEnterPreferences] = React.useState(
    props.availability_info.preferred_to > 0
  );

  //Modal styling object
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #E2E8F0",
    borderRadius: "15px",
    boxShadow: 24,
    p: 4,
  };

  // An array of abbreviated day names
  const day_abbrevs = createDayArray(props.businessWeekStartDayIndex);

  // useEffect hook to set the initially selected day
  React.useEffect(() => {
    let appliedToCopy = [false, false, false, false, false, false, false];
    appliedToCopy[props.d] = true;
    setAppliedTo(appliedToCopy);
  }, []);

  // Function to handle the change in the selected days
  function handleAppliedToChange(day_i) {
    let appliedToCopy = [...appliedTo];
    if (day_i !== props.d) {
      appliedToCopy[day_i] = !appliedTo[day_i];
    }
    console.log(appliedToCopy);
    setAppliedTo(appliedToCopy);
  }

  // Render the group of buttons for selecting the days to which the availability applies
  function appliedToButtonGroup() {
    return (
      <div className="w-full mt-2 bg-slate-50 rounded-lg flex">
        <ThemeProvider theme={theme}>
          <ButtonGroup
            style={{
              flex: 1,
              border: "1px solid #f1f5f9",
              borderRadius: "7px",
            }}
          >
            {appliedTo.map((d, i) => (
              <Button
                fullWidth
                color="neutral"
                size="small"
                style={{
                  border: d ? "1px solid #6d28d9" : "1px solid #f8fafc",
                  borderRadius: "7px",
                  backgroundColor: d ? "#ede9fe" : "transparent",
                  padding: "6px",
                  margin: "0 0.5px",
                }}
                key={i}
                onClick={() => handleAppliedToChange(i)}
              >
                {day_abbrevs[i]}
              </Button>
            ))}
          </ButtonGroup>
        </ThemeProvider>
      </div>
    );
  }

  // Function to get radio value
  function getRadioValue(state) {
    if ((state.available_from === 0) & (state.available_to === 24)) {
      return "all-day";
    } else if ((state.available_from === 0) & (state.available_to === 0)) {
      return "not-available";
    } else {
      return "custom-availability";
    }
  }

  // useEffect hook to update the radio button value when the availability state changes
  useEffect(() => {
    setValue(getRadioValue(state));
  }, [state]);

  useEffect(() => {
    if (props.saveAvailabilityFinished) {
      setSavingLoading(false);
      props.handleClose();
    }
  }, [props.saveAvailabilityFinished]);

  // Function to handle the click event of the radio buttons
  function handleRadioClick(value) {
    if (value === "all-day") {
      setState({
        ...state,
        ["available_from"]: 0,
        ["available_to"]: 24,
        ["available_from_2"]: null,
        ["available_to_2"]: null,
        ["preferred_from"]: 0,
        ["preferred_to"]: 0,
      });
      setAvailabilityInfoString({
        ...availabilityInfoString,
        ["available_from"]: props.get_t_str(0),
        ["available_to"]: props.get_t_str(24),
        ["available_from_2"]: null,
        ["available_to_2"]: null,
        ["preferred_from"]: props.get_t_str(0),
        ["preferred_to"]: props.get_t_str(0),
      });
      setEnterSplitAvailability(false)
    } else if (value === "not-available") {
      setState({
        ...state,
        ["available_from"]: 0,
        ["available_to"]: 0,
        ["available_from_2"]: null,
        ["available_to_2"]: null,
        ["preferred_from"]: 0,
        ["preferred_to"]: 0,
      });
      setAvailabilityInfoString({
        ...availabilityInfoString,
        ["available_from"]: props.get_t_str(0),
        ["available_to"]: props.get_t_str(0),
        ["available_from_2"]: null,
        ["available_to_2"]: null,
        ["preferred_from"]: props.get_t_str(0),
        ["preferred_to"]: props.get_t_str(0),
      });
      setEnterSplitAvailability(false)
      setEnterPreferences(false)
    } else {
      setState({
        ...state,
        ["available_from"]: 8,
        ["available_to"]: 16,
        ["preferred_from"]: 0,
        ["preferred_to"]: 0,
      });
      setAvailabilityInfoString({
        ...availabilityInfoString,
        ["available_from"]: props.get_t_str(8),
        ["available_to"]: props.get_t_str(16),
        ["preferred_from"]: props.get_t_str(0),
        ["preferred_to"]: props.get_t_str(0),
      });
      setEnterSplitAvailability(false)
    }
  }

  // Function to handle the change event of the default checkbox
  function handleCheckChange(e) {
    const { name, checked } = e.target;
    setState({
      ...state,
      ["default"]: checked,
    });
  }

  // Function to handle the change event of the availability text fields
  function handleAvailabilityInfo(evt) {
    const value = evt.target.value;
    setAvailabilityInfoString({
      ...availabilityInfoString,
      [evt.target.name]: value,
    });
    console.log(text_to_num(value));
    setState({
      ...state,
      [evt.target.name]: text_to_num(value),
    });
  }

  // Function to submit and close the modal
  function submitAndClose() {
    let availability_info = { ...state };
    availability_info["store_team_member"] = props.p.id;
    props.editAvailability(availability_info, appliedTo);
    setSavingLoading(true);
  }

  // Function to check if the availability inputs are valid
  const checkIfValid = () => {
    let valid = true;
    if (state.available_to < state.available_from) {
      valid = false;
    }
    if (state.available_to_2 && (state.available_to_2 < state.available_from_2)) {
      valid = false;
    }

    // Check if available_2 comes after available range
    if (state.available_to_2 && (state.available_from_2 <= state.available_to)) {
      valid = false;
    }

    // Check if preferred range is valid
    if (state.preferred_to < state.preferred_from) {
      valid = false;
    }

    // Check if preferred range is within either available or available_2 ranges
    const isWithinAvailableRange = state.preferred_to ? (
      state.preferred_from >= state.available_from &&
      state.preferred_to <= state.available_to
    ) : true;

    const isWithinAvailable2Range = (state.preferred_to && state.available_from_2 && state.available_to_2) ? (
      state.preferred_from >= state.available_from_2 &&
      state.preferred_to <= state.available_to_2
    ) : true;

    // Ensure the preferred range falls within at least one of the available ranges
    if (!isWithinAvailableRange && !isWithinAvailable2Range) {
      valid = false;
    }

    return valid;
  };


  function handleEnterPreferencesChange(e) {
    const { checked } = e.target;
    setEnterPreferences(checked);
    if (!checked) {
      setState({
        ...state,
        ["preferred_from"]: 0,
        ["preferred_to"]: 0,
      });
      setAvailabilityInfoString({
        ...availabilityInfoString,
        ["preferred_from"]: availabilityInfoString.available_from,
        ["preferred_to"]: availabilityInfoString.available_to,
      });
    }
  }

  function handleSplitAvailabilityChecked(e) {
    const { checked } = e.target
    setEnterSplitAvailability(checked)
    if (!checked) {
      setState({
        ...state,
        ["available_from_2"]: null,
        ["available_to_2"]: null,
      });
    } else {
      setState({
        ...state,
        ["available_from_2"]: state.available_to + 2,
        ["available_to_2"]: state.available_to + 6,
      });
      setAvailabilityInfoString({
        ...availabilityInfoString,
        ["available_from_2"]: props.get_t_str(state.available_to + 2),
        ["available_to_2"]: props.get_t_str(state.available_to + 6),
      });
    }
  }

  // function generatePreferenceOptions(start, end) {
  //   const options = [];
  //   let currentTime = start;

  //   while (currentTime <= end) {
  //     options.push(get_t_str(currentTime));
  //     currentTime += .25
  //   }

  //   return options;
  // }

  const [activeTab, setActiveTab] = React.useState("Availability");

  function handleTabButtonPress(target) {
    setActiveTab(target);
  }

  // Time off tab

  const [showEndDate, setShowEndDate] = React.useState(false);
  const [dateStart, setDateStart] = React.useState(props.date_str);
  const [dateEnd, setDateEnd] = React.useState(props.date_str);

  const time_off_default_data = {
    title: "",
    note: "",
    date_lower_bound: dateStart,
    date_upper_bound: null,
    stm_id: props.p.id,
  };

  const [modalDataTimeOff, setModalDataTimeOff] = React.useState(
    time_off_default_data
  );

  function handleChangeTimeOff(event) {
    const { name, value } = event.target;
    setModalDataTimeOff((prevModalData) => ({
      ...prevModalData,
      [name]: value,
    }));
  }

  // For the start date datepicker
  const [showDailyDatePicker, setShowDailyDatePicker] = React.useState(false);
  const datePickerRef = React.useRef(null);

  function toggleDailyDatePicker() {
    setShowDailyDatePicker((prevState) => !prevState);
  }

  React.useEffect(() => {
    // This function checks if the clicked target is inside the date picker component
    function handleClickOutside(event) {
      if (
        datePickerRef.current &&
        !datePickerRef.current.contains(event.target)
      ) {
        setShowDailyDatePicker(false);
      }
    }

    // Add the event listener to the document when the component is mounted
    if (showDailyDatePicker) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    // Clean up the event listener when the component is unmounted or the effect re-runs
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showDailyDatePicker]); // Re-run the effect when 'showDailyDatePicker' changes

  // For the end date datepicker
  const [showDailyDatePicker2, setShowDailyDatePicker2] = React.useState(false);
  const datePickerRef2 = React.useRef(null);

  function toggleDailyDatePicker2() {
    setShowDailyDatePicker2((prevState) => !prevState);
  }

  React.useEffect(() => {
    // This function checks if the clicked target is inside the date picker component
    function handleClickOutside2(event) {
      if (
        datePickerRef2.current &&
        !datePickerRef2.current.contains(event.target)
      ) {
        setShowDailyDatePicker2(false);
      }
    }

    // Add the event listener to the document when the component is mounted
    if (showDailyDatePicker2) {
      document.addEventListener("mousedown", handleClickOutside2);
    }

    // Clean up the event listener when the component is unmounted or the effect re-runs
    return () => {
      document.removeEventListener("mousedown", handleClickOutside2);
    };
  }, [showDailyDatePicker2]); // Re-run the effect when 'showDailyDatePicker' changes

  function submitTimeOffAndClose() {
    props.request_time_off(
      modalDataTimeOff.title,
      modalDataTimeOff.note,
      showEndDate ? dateStart : props.date_str,
      showEndDate ? dateEnd : null,
      modalDataTimeOff.stm_id
    );

    props.handleClose();
  }


  return (
    <div>
      <Drawer anchor="right" open={props.open} onClose={props.handleClose}>
        <div className="w-[450px] h-full">
          <div className="max-h-screen overflow-auto">
            <DrawerHeader
              title={props.p.first_name + " " + props.p.last_name}
              onClose={props.handleClose}
            />
            <div className="w-full flex px-6">
              <div className="w-60">
                <TabsTwo
                  activeTab={activeTab}
                  handleTabButtonPress={handleTabButtonPress}
                  tab1="Availability"
                  tab2="Time Off"
                />
              </div>
            </div>
            {activeTab === "Availability" ? (
              <div className="w-full px-6 mt-6">
                <div>
                  <h3 className="text-slate-900 text-base">Availability</h3>
                  <p className="text-slate-500 mt-1 text-sm">
                    Choose your desired default availability for{" "}
                    {props.day_of_week_name}s
                  </p>
                  <div className="bg-white border border-slate-200 rounded-2xl overflow-hidden mt-3">
                    <div
                      className="border-b border-slate-200 hover:bg-violet-50 px-4 h-14 flex flex-row items-center justify-between cursor-pointer"
                      onClick={() => handleRadioClick("not-available")}
                    >
                      <p className="text-slate-900 text-sm">Unavailable</p>
                      {value === "not-available" && (
                        <div className="text-violet-700">
                          <CheckIcon color="#6d28d9" />
                        </div>
                      )}
                    </div>
                    <div
                      className="border-b border-slate-200 hover:bg-violet-50 px-4 h-14 flex flex-row items-center justify-between cursor-pointer"
                      onClick={() => handleRadioClick("all-day")}
                    >
                      <p className="text-slate-900 text-sm">All Day</p>
                      {value === "all-day" && (
                        <div className="text-violet-700">
                          <CheckIcon color="#6d28d9" />
                        </div>
                      )}
                    </div>
                    <div
                      className="px-4 h-14 hover:bg-violet-50 flex flex-row items-center justify-between cursor-pointer"
                      onClick={() => handleRadioClick("custom-availability")}
                    >
                      <p className="text-slate-900 text-sm">Custom</p>
                      {value === "custom-availability" && (
                        <div className="text-violet-700">
                          <CheckIcon color="#6d28d9" />
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="mt-6 w-full">
                    {value === "custom-availability" && (
                      <div className="w-full flex items-center justify-between pb-5 border-b border-slate-200">
                        <div className="w-full">
                          <div>
                            <p className="text-slate-900 text-sm">
                              Set custom availability for{" "}
                              {props.day_of_week_name}
                            </p>
                          </div>
                          <div className="w-full mt-3 flex items-center">
                            <div className="w-[42%]">
                              <FormControl fullWidth size="small">
                                <Autocomplete
                                  labelId="available-from-label"
                                  id="available-from"
                                  disableClearable
                                  options={generateTimeOptions(
                                    props.start_qhnum
                                  )}
                                  value={availabilityInfoString.available_from}
                                  onChange={(event, newValue) => {
                                    handleAvailabilityInfo({
                                      target: {
                                        name: "available_from",
                                        value: newValue,
                                      },
                                    });
                                  }}
                                  filterOptions={filterOptions}
                                  size="small"
                                  sx={{
                                    "&.Mui-focused .MuiOutlinedInput-notchedOutline":
                                      {
                                        borderColor: "#6d28d9",
                                      },
                                  }}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      sx={{
                                        "& .MuiOutlinedInput-root": {
                                          borderRadius: "8px !important",
                                          height: "45px",
                                          "&.Mui-focused .MuiOutlinedInput-notchedOutline":
                                            {
                                              borderColor: "#6d28d9",
                                            },
                                          backgroundColor: "white",
                                        },
                                      }}
                                    />
                                  )}
                                />
                              </FormControl>
                            </div>
                            <div className="text-slate-500 w-[16%] flex items-center justify-center">
                              <ArrowForwardIcon />
                            </div>
                            <div className="w-[42%]">
                              <FormControl fullWidth size="small">
                                <Autocomplete
                                  labelId="available-to-label"
                                  id="available-to"
                                  disableClearable
                                  options={generateTimeOptions(
                                    props.start_qhnum
                                  )}
                                  value={availabilityInfoString.available_to}
                                  onChange={(event, newValue) => {
                                    handleAvailabilityInfo({
                                      target: {
                                        name: "available_to",
                                        value: newValue,
                                      },
                                    });
                                  }}
                                  filterOptions={filterOptions}
                                  size="small"
                                  sx={{
                                    "&.Mui-focused .MuiOutlinedInput-notchedOutline":
                                      {
                                        borderColor: "#6d28d9",
                                      },
                                  }}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      sx={{
                                        "& .MuiOutlinedInput-root": {
                                          borderRadius: "8px !important",
                                          height: "45px",
                                          "&.Mui-focused .MuiOutlinedInput-notchedOutline":
                                            {
                                              borderColor: "#6d28d9",
                                            },
                                          backgroundColor: "white",
                                        },
                                      }}
                                    />
                                  )}
                                />
                              </FormControl>
                            </div>
                          </div>
                          <div className="mt-3 flex items-center">
                            <PurpleSwitch
                              checked={enterSplitAvailability}
                              onChange={handleSplitAvailabilityChecked}
                            />
                            <p className="text-slate-500 text-sm">
                              Split availability
                            </p>
                          </div>
                          {enterSplitAvailability && (
                            <div className="w-full mt-3 flex items-center">
                              <div className="w-[42%]">
                                <FormControl fullWidth size="small">
                                  <Autocomplete
                                    labelId="available-from-label"
                                    id="available-from-2"
                                    disableClearable
                                    options={generateTimeOptions(
                                      props.start_qhnum
                                    )}
                                    value={
                                      availabilityInfoString.available_from_2
                                    }
                                    onChange={(event, newValue) => {
                                      handleAvailabilityInfo({
                                        target: {
                                          name: "available_from_2",
                                          value: newValue,
                                        },
                                      });
                                    }}
                                    filterOptions={filterOptions}
                                    size="small"
                                    sx={{
                                      "&.Mui-focused .MuiOutlinedInput-notchedOutline":
                                        {
                                          borderColor: "#6d28d9",
                                        },
                                    }}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        sx={{
                                          "& .MuiOutlinedInput-root": {
                                            borderRadius: "8px !important",
                                            height: "45px",
                                            "&.Mui-focused .MuiOutlinedInput-notchedOutline":
                                              {
                                                borderColor: "#6d28d9",
                                              },
                                            backgroundColor: "white",
                                          },
                                        }}
                                      />
                                    )}
                                  />
                                </FormControl>
                              </div>
                              <div className="text-slate-500 w-[16%] flex items-center justify-center">
                                <ArrowForwardIcon />
                              </div>
                              <div className="w-[42%]">
                                <FormControl fullWidth size="small">
                                  <Autocomplete
                                    labelId="available-to-label"
                                    id="available-to-2"
                                    disableClearable
                                    options={generateTimeOptions(
                                      props.start_qhnum
                                    )}
                                    value={availabilityInfoString.available_to_2}
                                    onChange={(event, newValue) => {
                                      handleAvailabilityInfo({
                                        target: {
                                          name: "available_to_2",
                                          value: newValue,
                                        },
                                      });
                                    }}
                                    filterOptions={filterOptions}
                                    size="small"
                                    sx={{
                                      "&.Mui-focused .MuiOutlinedInput-notchedOutline":
                                        {
                                          borderColor: "#6d28d9",
                                        },
                                    }}
                                    renderInput={(params) => (
                                      <TextField
                                        {...params}
                                        sx={{
                                          "& .MuiOutlinedInput-root": {
                                            borderRadius: "8px !important",
                                            height: "45px",
                                            "&.Mui-focused .MuiOutlinedInput-notchedOutline":
                                              {
                                                borderColor: "#6d28d9",
                                              },
                                            backgroundColor: "white",
                                          },
                                        }}
                                      />
                                    )}
                                  />
                                </FormControl>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="pb-3 border-b border-slate-200 mt-3">
                    {(value === "custom-availability" ||
                      value === "all-day") && (
                      <div className="mt-2 flex items-center">
                        <FormControlLabel
                          size="small"
                          control={
                            <Checkbox
                              name="enter_preferences"
                              checked={enterPreferences}
                              onChange={handleEnterPreferencesChange}
                              size="small"
                              sx={{
                                color: "#6d28d9",
                                "&.Mui-checked": {
                                  color: "#6d28d9",
                                },
                              }}
                            />
                          }
                        />
                        <p className="text-slate-900 text-sm -ml-3">
                          Enter preferences
                        </p>
                      </div>
                    )}
                    <div>
                      {enterPreferences && (
                        <div className="mt-2 flex items-center justify-between relative mb-2">
                          <div className="w-[42%]">
                            <FormControl fullWidth size="small">
                              <Autocomplete
                                labelId="preferred-from-label"
                                id="preferred-from"
                                disableClearable
                                options={generateTimeOptions(props.start_qhnum)}
                                value={availabilityInfoString.preferred_from}
                                onChange={(event, newValue) => {
                                  handleAvailabilityInfo({
                                    target: {
                                      name: "preferred_from",
                                      value: newValue,
                                    },
                                  });
                                }}
                                filterOptions={filterOptions}
                                size="small"
                                sx={{
                                  "&.Mui-focused .MuiOutlinedInput-notchedOutline":
                                    {
                                      borderColor: "#6d28d9",
                                    },
                                }}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    sx={{
                                      "& .MuiOutlinedInput-root": {
                                        borderRadius: "8px !important",
                                        height: "45px",
                                        "&.Mui-focused .MuiOutlinedInput-notchedOutline":
                                          {
                                            borderColor: "#6d28d9",
                                          },
                                        backgroundColor: "white",
                                      },
                                    }}
                                  />
                                )}
                              />
                            </FormControl>
                          </div>
                          <div className="text-slate-500 w-[16%] flex items-center justify-center">
                            <ArrowForwardIcon />
                          </div>
                          <div className="w-[42%]">
                            <FormControl fullWidth size="small">
                              <Autocomplete
                                labelId="preferred-to-label"
                                id="preferred-to"
                                disableClearable
                                options={generateTimeOptions(props.start_qhnum)}
                                value={availabilityInfoString.preferred_to}
                                onChange={(event, newValue) => {
                                  handleAvailabilityInfo({
                                    target: {
                                      name: "preferred_to",
                                      value: newValue,
                                    },
                                  });
                                }}
                                filterOptions={filterOptions}
                                size="small"
                                sx={{
                                  "&.Mui-focused .MuiOutlinedInput-notchedOutline":
                                    {
                                      borderColor: "#6d28d9",
                                    },
                                }}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    sx={{
                                      "& .MuiOutlinedInput-root": {
                                        borderRadius: "8px !important",
                                        height: "45px",
                                        "&.Mui-focused .MuiOutlinedInput-notchedOutline":
                                          {
                                            borderColor: "#6d28d9",
                                          },
                                        backgroundColor: "white",
                                      },
                                    }}
                                  />
                                )}
                              />
                            </FormControl>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="mt-5 pb-5 border-slate-200">
                    <h3 className="text-sm text-slate-900 font-bold">
                      Apply to
                    </h3>
                    <div className="mt-4">{appliedToButtonGroup()}</div>
                  </div>
                  {/* <div>
                    <div className="w-full mt-5 mb-3">
                      <h3 className="text-sm text-slate-900 font-bold">Preview</h3>
                    </div>
                    <AvailabilityCardModalDisplay
                      state={state}
                      get_t_str={props.get_t_str}
                    />
                  </div> */}
                </div>
              </div>
            ) : (
              <div className="w-full px-6 mt-6">
                <h3 className="text-slate-900 text-base">Time Off</h3>
                {!showEndDate && (
                  <p className="text-slate-500 mt-1 text-sm">
                    Set this date,{" "}
                    <span className="font-bold">{props.date_name}</span>, as
                    time off for {props.p.first_name + " " + props.p.last_name}
                  </p>
                )}
                <div className="mt-8">
                  <h3 className="text-slate-500 text-sm">Title</h3>
                  <div className="mt-1">
                    <TextField
                      fullWidth
                      id="outlined-basic"
                      variant="outlined"
                      placeholder="e.g. Vacation"
                      size="small"
                      name="title"
                      InputProps={{
                        sx: {
                          backgroundColor: "white",
                          borderRadius: "12px",
                          paddingY: "5px",
                          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                            borderColor: "#6d28d9",
                          },
                        },
                      }}
                      value={modalDataTimeOff.title}
                      onChange={handleChangeTimeOff}
                    />
                  </div>
                </div>
                {/* <div className="mt-5">
                  <h3 className="text-slate-500 text-sm">Note</h3>
                  <div className="mt-1">
                    <TextField
                      fullWidth
                      id="outlined-multiline-static"
                      multiline
                      rows={3}
                      variant="outlined"
                      placeholder="Leave a note (optional)"
                      size="small"
                      name="note"
                      InputProps={{
                        sx: {
                          backgroundColor: "white",
                          borderRadius: "12px",
                          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                            borderColor: "#6d28d9",
                          },
                        },
                      }}
                      value={modalDataTimeOff.note}
                      onChange={handleChangeTimeOff}
                    />
                  </div>
                </div>
                Put the switch for showEndDate here and the date pickers*/}

                <div className="mt-5 flex items-center">
                  <PurpleSwitch
                    checked={showEndDate}
                    onChange={() => setShowEndDate(!showEndDate)}
                  />
                  <p className="text-slate-500 text-sm">
                    {props.p.first_name} needs more than one day off
                  </p>
                </div>
                {showEndDate && (
                  <div className="mt-5">
                    <h3 className="text-slate-500 text-sm">Start Date*</h3>
                    <div className="mt-1">
                      <DatePickerDropdown
                        showDailyDatePicker={showDailyDatePicker}
                        toggleDailyDatePicker={toggleDailyDatePicker}
                        datePickerRef={datePickerRef}
                        setDate={setDateStart}
                        minEffectiveDate={modalDataTimeOff.date_lower_bound}
                      />
                    </div>
                  </div>
                )}
                {showEndDate && (
                  <div className="mt-5">
                    <p className="text-slate-500 text-sm">End Date*</p>
                    <div className="mt-1">
                      <DatePickerDropdown
                        showDailyDatePicker={showDailyDatePicker2}
                        toggleDailyDatePicker={toggleDailyDatePicker2}
                        datePickerRef={datePickerRef2}
                        setDate={setDateEnd}
                        minEffectiveDate={dateStart}
                      />
                    </div>
                  </div>
                )}
              </div>
            )}
            <div className="h-[120px]"></div>
            {activeTab === "Availability" ? (
              <DrawerFooterContainerSmall>
                <LoadingButton
                  variant="contained"
                  loading={savingLoading}
                  loadingPosition="end"
                  disabled={!checkIfValid()}
                  endIcon={
                    props.new_availability ? <AddTaskIcon /> : <SaveIcon />
                  }
                  onClick={() => submitAndClose()}
                  sx={{
                    width: "100%",
                    textTransform: "none",
                    borderRadius: "50px",
                    paddingY: "14px",
                    backgroundColor: "#6d28d9",
                    "&:hover": {
                      backgroundColor: "#5b21b6",
                    },
                    fontSize: "16px",
                  }}
                >
                  {props.new_availability
                    ? "Create Availability"
                    : "Save Availability"}
                </LoadingButton>
              </DrawerFooterContainerSmall>
            ) : (
              <DrawerFooterContainerSmall>
                <LoadingButton
                  variant="contained"
                  // loading={savingLoading}
                  // loadingPosition="end"
                  // disabled={!checkIfValid()}
                  endIcon={<AddTaskIcon />}
                  onClick={() => submitTimeOffAndClose()}
                  sx={{
                    width: "100%",
                    textTransform: "none",
                    borderRadius: "50px",
                    paddingY: "14px",
                    backgroundColor: "#6d28d9",
                    "&:hover": {
                      backgroundColor: "#5b21b6",
                    },
                    fontSize: "16px",
                  }}
                >
                  Create Time Off
                </LoadingButton>
              </DrawerFooterContainerSmall>
            )}
          </div>
        </div>
      </Drawer>
    </div>
  );
}

export default AvailabilityInputsModal;
