import React, { useState, useEffect } from "react";
import DaypartSelector from "./DaypartSelector";
import ScheduledNameCard from "./ScheduledNameCard";
import ScheduledNameCard2 from "./ScheduleNameCard2";
import TaskNameCard from "./TaskNameCard";
import IconButton from "@mui/material/IconButton";
import AutoFixHighIcon from "@mui/icons-material/AutoFixHigh";
import Tooltip from "@mui/material/Tooltip";
import { DragDropContext, Droppable, Draggable } from "@hello-pangea/dnd";

function DailyPlanInfo2(props) {
  const [assignedRoles, setAssignedRoles] = useState({});
  const [noShows, setNoShows] = useState([]);
  const [dayparts, setDayparts] = useState([]);
  const [selectedDaypart, setSelectedDaypart] = useState(null);
  const [unassignedTasks, setUnassignedTasks] = useState([
    {
      id: "task-1",
      name: "Prep food",
      start_time: 4,
      end_time: 12,
      description: "Prepare ingredients and set up the cooking stations.",
    },
    {
      id: "task-2",
      name: "Do dishes",
      start_time: 10,
      end_time: 15,
      description: "Wash and sanitize all kitchen utensils and dishes.",
    },
    {
      id: "task-3",
      name: "Clean bar",
      start_time: 8,
      end_time: 20,
      description: "Wipe down counters, restock supplies, and organize the bar area.",
    },
    {
      id: "task-4",
      name: "Stock shelves",
      start_time: 9,
      end_time: 13,
      description: "Refill shelves with products and organize inventory.",
    },
    {
      id: "task-5",
      name: "Mop floors",
      start_time: 15,
      end_time: 17,
      description: "Mop all floor surfaces to ensure cleanliness.",
    },
    {
      id: "task-6",
      name: "Prepare beverages",
      start_time: 11,
      end_time: 14,
      description: "Mix and prepare drinks according to customer orders.",
    },
    {
      id: "task-7",
      name: "Clean tables",
      start_time: 16,
      end_time: 18,
      description: "Wipe down tables and ensure they are ready for new customers.",
    },
    {
      id: "task-8",
      name: "Take out trash",
      start_time: 13,
      end_time: 14,
      description: "Dispose of all waste in the designated trash bins.",
    },
    {
      id: "task-9",
      name: "Organize storage",
      start_time: 14,
      end_time: 16,
      description: "Arrange and categorize items in the storage room.",
    },
    {
      id: "task-10",
      name: "Set up dining area",
      start_time: 7,
      end_time: 9,
      description: "Set up tables, chairs, and place settings for guests.",
    },
    {
      id: "task-11",
      name: "Check inventory",
      start_time: 12,
      end_time: 13,
      description: "Verify inventory levels and place orders if necessary.",
    },
    {
      id: "task-12",
      name: "Assist with deliveries",
      start_time: 14,
      end_time: 15,
      description: "Help unload and store deliveries as they arrive.",
    },
]);

  const [assignedTasks, setAssignedTasks] = useState([]);

  useEffect(() => {
    const sortedDayparts = [...props.dayparts].sort(
      (a, b) => a.start_time - b.start_time
    );

    setDayparts(sortedDayparts);
    setSelectedDaypart(sortedDayparts[0]);
  }, [props.dayparts]);

  useEffect(() => {
    const convertedRoleAssignments = props.roleAssignments.reduce(
      (acc, assignment) => {
        const daypart = props.dayparts.find(
          (daypart) => daypart.id === assignment.daypart
        );
        const daypartName = daypart ? daypart.name : null;

        const employee = props.combinedData.find(
          (employee) =>
            employee.store_team_member_id === assignment.store_team_member
        );
        const employeeName = employee ? employee.name : null;

        if (daypartName && employeeName) {
          if (!acc[daypartName]) {
            acc[daypartName] = {};
          }

          if (!acc[daypartName][assignment.deployment_role]) {
            acc[daypartName][assignment.deployment_role] = [];
          }

          acc[daypartName][assignment.deployment_role].push(employeeName);
        }
        return acc;
      },
      {}
    );

    setAssignedRoles(convertedRoleAssignments);
  }, [props.roleAssignments]);


  const handleDaypartSelection = (daypart) => {
    setSelectedDaypart(daypart);
  };

  const handleNewDaypart = (name, start_time) => {
    const newDaypart = { name, start_time };
    const sortedDayparts = [...dayparts, newDaypart].sort(
      (a, b) => a.start_time - b.start_time
    );

    const updatedDayparts = sortedDayparts.map((daypart, index) => {
      if (index < sortedDayparts.length - 1) {
        return { ...daypart, end_time: sortedDayparts[index + 1].start_time };
      } else {
        return { ...daypart, end_time: 24 };
      }
    });
    props.saveDayparts(updatedDayparts);
    setDayparts(updatedDayparts);

    // Initialize assignedRoles for the new daypart
    setAssignedRoles((prevAssignedRoles) => ({
      ...prevAssignedRoles,
      [name]: {},
    }));

    // Set selectedDaypart to the new daypart
    setSelectedDaypart(updatedDayparts[0]);
  };

  const handleDeleteDaypart = () => {
    if (dayparts.length === 1) {
      alert("You must have at least one daypart.");
      return;
    }

    const confirmation = window.confirm(
      "Are you sure you want to delete this daypart for this location?"
    );

    if (!confirmation) {
      return;
    }

    const updatedDayparts = dayparts.filter(
      (daypart) => daypart.name !== selectedDaypart.name
    );

    props.deleteDaypart(selectedDaypart.id);

    // Recalculate the end times for the remaining dayparts
    const daypartsToUpdate = updatedDayparts.map((daypart, index) => {
      if (index < updatedDayparts.length - 1) {
        return { ...daypart, end_time: updatedDayparts[index + 1].start_time };
      } else {
        return { ...daypart, end_time: 24 };
      }
    });

    setDayparts(daypartsToUpdate);
    props.saveDayparts(daypartsToUpdate);

    // Remove the assignedRoles for the deleted daypart
    setAssignedRoles((prevAssignedRoles) => {
      const newAssignedRoles = { ...prevAssignedRoles };
      delete newAssignedRoles[selectedDaypart.name];
      return newAssignedRoles;
    });

    // Set the selectedDaypart to the first daypart in the updated list
    setSelectedDaypart(daypartsToUpdate[0]);
  };


  const getFilteredSchedule = (schedule) => {
    if (!selectedDaypart) {
      return schedule;
    }

    return schedule.filter((shift) => {
      const shiftStart = shift.shift_start;
      const shiftEnd = shift.shift_end;

      return (
        (shiftStart >= selectedDaypart.start_time &&
          shiftStart < selectedDaypart.end_time) ||
        (shiftEnd > selectedDaypart.start_time &&
          shiftEnd <= selectedDaypart.end_time) ||
        (shiftStart <= selectedDaypart.start_time &&
          shiftEnd >= selectedDaypart.end_time)
      );
    });
  };

  const getFilteredTasks = (tasks) => {
    if (!selectedDaypart) {
      return tasks;
    }

    return tasks.filter((task) => {
      const taskStart = task.start_time;
      const taskEnd = task.end_time;

      return (
        (taskStart >= selectedDaypart.start_time &&
          taskStart < selectedDaypart.end_time) ||
        (taskEnd > selectedDaypart.start_time &&
          taskEnd <= selectedDaypart.end_time) ||
        (taskStart <= selectedDaypart.start_time &&
          taskEnd >= selectedDaypart.end_time)
      );
    });
  };

  const isAssigned = (name) => {
    if (!selectedDaypart || !assignedRoles[selectedDaypart.name]) {
      return false;
    }

    const rolesInDaypart = assignedRoles[selectedDaypart.name];

    return Object.values(rolesInDaypart).some((namesArray) => {
      if (!Array.isArray(namesArray)) {
        return false;
      }
      return namesArray.includes(name);
    });
  };

  const getRoleAssignmentId = (roleId, name) => {
    const employee = props.combinedData.find(
      (employee) => employee.name === name
    );

    if (!employee) return null;

    const assignment = props.roleAssignments.find(
      (assignment) =>
        assignment.daypart === selectedDaypart.id &&
        assignment.store_team_member === employee.store_team_member_id &&
        assignment.deployment_role === roleId
    );

    return assignment ? assignment.id : null;
  };

  const unassignRole = (roleIndex, name) => {
    const newAssignedRoles = { ...assignedRoles[selectedDaypart.name] };
    if (newAssignedRoles[roleIndex]) {
      const assignmentId = getRoleAssignmentId(roleIndex, name);
      props.deleteRoleAssignment(assignmentId);
      newAssignedRoles[roleIndex] = newAssignedRoles[roleIndex].filter(
        (assignedName) => assignedName !== name
      );

      if (newAssignedRoles[roleIndex].length === 0) {
        delete newAssignedRoles[roleIndex];
      }

      setAssignedRoles({
        ...assignedRoles,
        [selectedDaypart.name]: {
          ...newAssignedRoles,
        },
      });
    }
  };

  const unassignedPeople = getFilteredSchedule(props.combinedData).filter(
    (employee) => !isAssigned(employee.name) && !noShows.includes(employee.name)
  );

  const assignedPeople = getFilteredSchedule(props.combinedData).filter(
    (employee) => isAssigned(employee.name)
  );


  // New code

  const employeesWorking = getFilteredSchedule(props.combinedData)

  console.log("EMPLOYEES WORKING", employeesWorking)

  const groupedEmployeesWorkingByRoleType = employeesWorking.reduce((acc, item) => {
    const roleType = item.role_type;
    if (!acc[roleType]) {
      acc[roleType] = [];
    }
    acc[roleType].push(item);
    return acc;
  }, {});

  console.log("BY ROLE TYPE", groupedEmployeesWorkingByRoleType)

  const onDragEnd = (result) => {
    const { destination, source, draggableId } = result;

    // If there's no destination, or the item is dropped outside a droppable area
    if (!destination) return;

    // If the item is dropped in the same place, no need to do anything
    if (destination.droppableId === source.droppableId && destination.index === source.index) return;

    // Handle the task assignment
    const task = unassignedTasks.find((task) => task.id === draggableId);

    if (task && destination.droppableId.startsWith("employee-")) {
      const employeeId = destination.droppableId.split('-')[1]; // Extract employeeId from droppableId

      // Update the employeeId of the task
      const updatedTask = { ...task, employeeId };

      // Assign the task to the employee
      setAssignedTasks((prevAssignedTasks) => [...prevAssignedTasks, updatedTask]);

      // Remove the task from the unassignedTasks list
      setUnassignedTasks((prevUnassignedTasks) =>
        prevUnassignedTasks.filter((t) => t.id !== task.id)
      );
    }
  };


  console.log("ASSIGNED TASKS", assignedTasks)


  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <div>
        <div className="flex">
          <div className="w-[22%]">
            <div className="mb-10">
              <div className="flex justify-between items-center">
                <h3 className="mb-1 text-gray-500">Unassigned</h3>
                <Tooltip title="Auto-assign to employees">
                  <IconButton
                    onClick={() =>
                      props.autoAssignRoles(selectedDaypart.id, unassignedPeople)
                    }
                  >
                    <AutoFixHighIcon />{" "}
                  </IconButton>
                </Tooltip>
              </div>
              <hr className="mb-2"></hr>
              <Droppable droppableId="unassignedTasks">
                {(provided) => (
                  <div {...provided.droppableProps} ref={provided.innerRef}>
                    {getFilteredTasks(unassignedTasks).length > 0 ? (
                      getFilteredTasks(unassignedTasks).map((task, index) => (
                        <Draggable key={task.id} draggableId={task.id} index={index}>
                          {(provided) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              className="mt-2"
                            >
                              <TaskNameCard task={task} isAssigned={false} />
                            </div>
                          )}
                        </Draggable>
                      ))
                    ) : (
                      <p className="text-sm text-gray-300">No tasks found.</p>
                    )}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </div>
            <div className="mb-10">
              <h3 className="mb-1 text-gray-500">Assigned</h3>
              <hr className="mb-2"></hr>
              {getFilteredTasks(assignedTasks).length > 0 ? (
                getFilteredTasks(assignedTasks).map((task) => (
                  <div className="mt-2">
                    <TaskNameCard task={task} isAssigned={true} />
                  </div>
                ))
              ) : (
                <p className="text-sm text-gray-300">No tasks found.</p>
              )}
            </div>
          </div>
          <div className="ml-20 w-[78%]">
            <div>
              <DaypartSelector
                dayparts={dayparts}
                selectedDaypart={selectedDaypart}
                onSelect={handleDaypartSelection}
                onAdd={handleNewDaypart}
                onDelete={handleDeleteDaypart}
              />
            </div>
            {Object.keys(groupedEmployeesWorkingByRoleType).map((roleType) => (
              <div className="mt-8" key={roleType}>
                <h2 className="text-2xl text-slate-500">{roleType}</h2>
                <div className="mt-2 flex flex-wrap gap-2">
                  {groupedEmployeesWorkingByRoleType[roleType].map((employee) => (
                    <Droppable key={employee.id} droppableId={`employee-${employee.id}`}>
                      {(provided, snapshot) => (
                        <div ref={provided.innerRef} {...provided.droppableProps} className="mt-2">
                          <ScheduledNameCard2
                            employee={employee}
                            selectedDaypart={selectedDaypart}
                            assignedTasks={assignedTasks.filter((task) => task.employeeId === employee.id.toString())}
                            snapshot={snapshot}
                          />
                          {provided.placeholder}
                        </div>
                      )}
                    </Droppable>
                  ))}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </DragDropContext>
  );
}
export default DailyPlanInfo2;
