import React, { useState } from "react";

function AddDaypartModal(props) {
  const isOpen = props.isOpen;
  const onClose = props.onClose;
  const onAdd = props.onAdd;

  const [name, setName] = useState("");
  const [start, setStart] = useState("");

  const handleSubmit = () => {
    if (name && start) {
      onAdd(name, Number(start), Number(start) + 4); // Assuming a default duration of 4 hours for the new daypart
      setName("");
      setStart("");
      onClose();
    }
  };

  return isOpen ? (
    <div className="fixed inset-0 flex items-center justify-center z-10">
      <div className="bg-white p-5 rounded-xl shadow-lg w-96 border border-slate-900">
        <h2 className="text-xl font-bold mb-4">Add New Daypart</h2>
        <input
          type="text"
          className="border border-slate-300 focus:outline-none focus:ring-0 focus:border-2 focus:border-violet-700 p-2 w-full mb-4 rounded-lg"
          placeholder="Daypart Name"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
        <input
          type="number"
          className="border border-slate-300 focus:outline-none focus:ring-0 focus:border-2 focus:border-violet-700 p-2 w-full mb-4 rounded-lg"
          placeholder="Start Time (hour)"
          min="0"
          max="23"
          value={start}
          onChange={(e) => setStart(e.target.value)}
        />
        <div className="flex justify-end">
          <button
            className="bg-white hover:bg-slate-50 border border-violet-700 text-violet-700 px-4 py-2 rounded-lg cursor-pointer"
            onClick={onClose}
          >
            Cancel
          </button>
          <button
            className="bg-violet-700 hover:bg-violet-800 text-white px-4 py-2 rounded-lg ml-2 cursor-pointer"
            onClick={handleSubmit}
          >
            Add
          </button>
        </div>
      </div>
    </div>
  ) : null;
}

export default AddDaypartModal;
