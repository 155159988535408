import React from "react";
import { makeStyles } from "@mui/styles";
import Drawer from "@mui/material/Drawer";
import CssBaseline from "@mui/material/CssBaseline";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import HomeIcon from "@mui/icons-material/Home";
import TimelineIcon from "@mui/icons-material/Timeline";
import StoreIcon from "@mui/icons-material/Store";
import InputIcon from "@mui/icons-material/Input";
import LocalLibraryIcon from "@mui/icons-material/LocalLibrary";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import PlaceIcon from "@mui/icons-material/Place";
import TuneIcon from "@mui/icons-material/Tune";
import WorkspacesIcon from "@mui/icons-material/Workspaces";
import DisplaySettingsIcon from "@mui/icons-material/DisplaySettings";
import TrackChangesIcon from "@mui/icons-material/TrackChanges";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import BeenhereIcon from "@mui/icons-material/Beenhere";
import SentimentSatisfiedAltIcon from "@mui/icons-material/SentimentSatisfiedAlt";
import SettingsIcon from "@mui/icons-material/Settings";
import Tooltip from "@mui/material/Tooltip";
import PendingActionsIcon from "@mui/icons-material/PendingActions";
import ShowChartIcon from "@mui/icons-material/ShowChart";
import PeopleIcon from "@mui/icons-material/People";
import PersonIcon from "@mui/icons-material/Person";
import EventAvailableIcon from "@mui/icons-material/EventAvailable";
import SchoolIcon from "@mui/icons-material/School";
import FolderZipIcon from "@mui/icons-material/FolderZip";
import { amplitude } from "../../Amplitude";
import PunchClockIcon from "@mui/icons-material/PunchClock";
import AddBoxOutlinedIcon from "@mui/icons-material/AddBoxOutlined";
import ChatIcon from "@mui/icons-material/Chat";
import EditNoteIcon from "@mui/icons-material/EditNote";

//import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";

const large_screen = false; // window.document.body.clientWidth > 1100;

const drawerWidth = large_screen ? 240 : 60;

const navBackgroundColor = "#111827"; // "#6d28d9"

const useStyles = makeStyles((theme) => ({
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: navBackgroundColor,
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
    color: "#DCDCDC",
    backgroundColor: navBackgroundColor,
  },
  listItemIcon: {
    color: "#DCDCDC",
  },
}));

const iconStyles = { color: "#DCDCDC" };
const linkStyles = {
  color: "inherit",
  textDecoration: "none",
};
export default function NavigationSideBar(props) {
  const classes = useStyles();

  function showLogo(large_screen) {
    if (large_screen) {
      return (
        <img
          src={process.env.PUBLIC_URL + "/images/logo_gray.png"}
          height="40"
          className="d-inline-block align-top"
          alt="RightWork logo"
          style={{ marginLeft: "28px", marginBottom: "35px" }}
        />
      );
    } else {
      return (
        <img
          src={process.env.PUBLIC_URL + "/images/grayicon.png"}
          height="30"
          className="d-inline-block align-top"
          alt="RightWork logo"
          style={{
            marginBottom: "35px",
            height: "40px",
            display: "inline-block",
          }}
        />
      );
    }
  }

  return (
    <div className={classes.root}>
      <CssBaseline />

      <Drawer
        className={classes.drawer}
        variant="permanent"
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <Toolbar />
        <div
          className={classes.drawerContainer}
          style={{ textAlign: "center" }}
        >
          {showLogo(large_screen)}
          <Divider />
          <List>
            {props.permissionRole !== "company_employee" && (
              <Tooltip title="Home" placement="right">
                <ListItem
                  button
                  key="Home"
                  style={{
                    padding: "10px 0px",
                    backgroundColor:
                      props.currentTab === "home"
                        ? "#2f3340"
                        : navBackgroundColor,
                  }}
                  onClick={() => {
                    props.setCurrentTab("home");
                    amplitude.getInstance().logEvent("Home Tab");
                  }}
                >
                  <ListItemIcon
                    className={classes.listItemIcon}
                    style={{ justifyContent: "center" }}
                  >
                    <HomeIcon />
                  </ListItemIcon>
                </ListItem>
              </Tooltip>
            )}
            {props.permissionRole !== "company_employee" && (
              <Tooltip title="Reporting" placement="right">
                <ListItem
                  button
                  key="Reporting"
                  style={{
                    padding: "10px 0px",
                    backgroundColor:
                      props.currentTab === "reporting"
                        ? "#2f3340"
                        : navBackgroundColor,
                  }}
                  onClick={() => props.setCurrentTab("reporting")}
                >
                  <ListItemIcon
                    className={classes.listItemIcon}
                    style={{ justifyContent: "center" }}
                  >
                    <ShowChartIcon />
                  </ListItemIcon>
                </ListItem>
              </Tooltip>
            )}
            {/* {props.permissionRole === "rightwork_internal" && (
              <Tooltip title="Contests" placement="right">
                <ListItem
                  button
                  key="Goals"
                  style={{
                    padding: "10px 0px",
                    backgroundColor:
                      props.currentTab === "goals"
                        ? "#2f3340"
                        : navBackgroundColor,
                  }}
                  onClick={() => {
                    props.setCurrentTab("goals");
                    amplitude.getInstance().logEvent("Contests Tab");
                  }}
                >
                  <ListItemIcon
                    className={classes.listItemIcon}
                    style={{ justifyContent: "center" }}
                  >
                    <TrackChangesIcon />
                  </ListItemIcon>
                </ListItem>
              </Tooltip>
            )} */}
            {/* {props.permissionRole === "rightwork_internal" && (
              <Tooltip title="Labor Model" placement="right">
                <ListItem
                  button
                  key="Settings"
                  style={{
                    padding: "10px 0px",
                    backgroundColor:
                      props.currentTab === "settings"
                        ? "#2f3340"
                        : navBackgroundColor,
                  }}
                  onClick={() => {
                    props.setCurrentTab("settings");
                    amplitude.getInstance().logEvent("Labor Settings Tab");
                  }}
                >
                  <ListItemIcon
                    className={classes.listItemIcon}
                    style={{ justifyContent: "center" }}
                  >
                    <TuneIcon />
                  </ListItemIcon>
                </ListItem>
              </Tooltip>
            )} */}
            {props.permissionRole === "rightwork_internal" && (
              <Tooltip title="Daily Plan" placement="right">
                <ListItem
                  button
                  key="Daily Plan"
                  style={{
                    padding: "10px 0px",
                    backgroundColor:
                      props.currentTab === "plan"
                        ? "#2f3340"
                        : navBackgroundColor,
                  }}
                  onClick={() => {
                    props.setCurrentTab("plan");
                    amplitude.getInstance().logEvent("Deployment Tab");
                  }}
                >
                  <ListItemIcon
                    className={classes.listItemIcon}
                    style={{ justifyContent: "center" }}
                  >
                    <AssignmentIndIcon />
                  </ListItemIcon>
                </ListItem>
              </Tooltip>
            )}
            {props.permissionRole !== "company_employee" && (
              <Tooltip title="Actions" placement="right">
                <ListItem
                  button
                  key="Actions"
                  style={{
                    padding: "10px 0px",
                    backgroundColor:
                      props.currentTab === "actions"
                        ? "#2f3340"
                        : navBackgroundColor,
                  }}
                  onClick={() => props.setCurrentTab("actions")}
                >
                  <ListItemIcon
                    className={classes.listItemIcon}
                    style={{ justifyContent: "center" }}
                  >
                    <PendingActionsIcon />
                  </ListItemIcon>
                </ListItem>
              </Tooltip>
            )}
            {props.permissionRole === "company_employee" && (
              <Tooltip title="Home" placement="right">
                <ListItem
                  button
                  key="EmployeeHome"
                  style={{
                    padding: "10px 0px",
                    backgroundColor:
                      props.currentTab === "employeeHome"
                        ? "#2f3340"
                        : navBackgroundColor,
                  }}
                  onClick={() => props.setCurrentTab("employeeHome")}
                >
                  <ListItemIcon
                    className={classes.listItemIcon}
                    style={{ justifyContent: "center" }}
                  >
                    <HomeIcon />
                  </ListItemIcon>
                </ListItem>
              </Tooltip>
            )}
            {/* {props.permissionRole === "rightwork_internal" && (
              <Tooltip title="Configurations" placement="right">
                <ListItem
                  button
                  key="Configurations"
                  style={{
                    padding: "10px 0px",
                    backgroundColor:
                      props.currentTab === "configurations"
                        ? "#2f3340"
                        : navBackgroundColor,
                  }}
                  onClick={() => props.setCurrentTab("configurations")}
                >
                  <ListItemIcon
                    className={classes.listItemIcon}
                    style={{ justifyContent: "center" }}
                  >
                    <SettingsIcon />
                  </ListItemIcon>
                </ListItem>
              </Tooltip>
            )} */}
            {props.permissionRole !== "company_employee" && (
              <Tooltip
                title="Department, Role, and Station Settings"
                placement="right"
              >
                <ListItem
                  button
                  key="Department, Role, and Station Settings"
                  style={{
                    padding: "10px 0px",
                    backgroundColor:
                      props.currentTab === "departmentRoleStationSettings"
                        ? "#2f3340"
                        : navBackgroundColor,
                  }}
                  onClick={() =>
                    props.setCurrentTab("departmentRoleStationSettings")
                  }
                >
                  <ListItemIcon
                    className={classes.listItemIcon}
                    style={{ justifyContent: "center" }}
                  >
                    <WorkspacesIcon />
                  </ListItemIcon>
                </ListItem>
              </Tooltip>
            )}
            {props.permissionRole === "rightwork_internal" && (
              <Tooltip
                title="Locations, Integrations, and Hours of Operation Settings"
                placement="right"
              >
                <ListItem
                  button
                  key="Locations, Integrations, and Hours of Operation Settings"
                  style={{
                    padding: "10px 0px",
                    backgroundColor:
                      props.currentTab === "locationsIntegrationsHoursSettings"
                        ? "#2f3340"
                        : navBackgroundColor,
                  }}
                  onClick={() =>
                    props.setCurrentTab("locationsIntegrationsHoursSettings")
                  }
                >
                  <ListItemIcon
                    className={classes.listItemIcon}
                    style={{ justifyContent: "center" }}
                  >
                    <PlaceIcon />
                  </ListItemIcon>
                </ListItem>
              </Tooltip>
            )}
            {(props.permissionRole === "rightwork_internal" ||
              props.permissionRole === "company_admin") && (
              <Tooltip title="Labor Model" placement="right">
                <ListItem
                  button
                  key="Labor Model"
                  style={{
                    padding: "10px 0px",
                    backgroundColor:
                      props.currentTab === "laborModel"
                        ? "#2f3340"
                        : navBackgroundColor,
                  }}
                  onClick={() => props.setCurrentTab("laborModel")}
                >
                  <ListItemIcon
                    className={classes.listItemIcon}
                    style={{ justifyContent: "center" }}
                  >
                    <DisplaySettingsIcon />
                  </ListItemIcon>
                </ListItem>
              </Tooltip>
            )}
            {props.permissionRole !== "company_employee" &&
              props.timeEntriesEnabled === true && (
                <Tooltip title="Time Entries" placement="right">
                  <ListItem
                    button
                    key="Time Entries"
                    style={{
                      padding: "10px 0px",
                      backgroundColor:
                        props.currentTab === "timeEntries"
                          ? "#2f3340"
                          : navBackgroundColor,
                    }}
                    onClick={() => props.setCurrentTab("timeEntries")}
                  >
                    <ListItemIcon
                      className={classes.listItemIcon}
                      style={{ justifyContent: "center" }}
                    >
                      <PunchClockIcon />
                    </ListItemIcon>
                  </ListItem>
                </Tooltip>
              )}
            {props.permissionRole !== "company_employee" && (
              <Tooltip title="Employees" placement="right">
                <ListItem
                  button
                  key="Employees Information"
                  style={{
                    padding: "10px 0px",
                    backgroundColor:
                      props.currentTab === "employeesInformation"
                        ? "#2f3340"
                        : navBackgroundColor,
                  }}
                  onClick={() => props.setCurrentTab("employeesInformation")}
                >
                  <ListItemIcon
                    className={classes.listItemIcon}
                    style={{ justifyContent: "center" }}
                  >
                    <PeopleIcon />
                  </ListItemIcon>
                </ListItem>
              </Tooltip>
            )}
            {
              <Tooltip title="Published Schedule" placement="right">
                <ListItem
                  button
                  key="Published Schedule"
                  style={{
                    padding: "10px 0px",
                    backgroundColor:
                      props.currentTab === "publishedSchedule"
                        ? "#2f3340"
                        : navBackgroundColor,
                  }}
                  onClick={() => props.setCurrentTab("publishedSchedule")}
                >
                  <ListItemIcon
                    className={classes.listItemIcon}
                    style={{ justifyContent: "center" }}
                  >
                    <EventAvailableIcon />
                  </ListItemIcon>
                </ListItem>
              </Tooltip>
            }
            {props.permissionRole === "company_employee" &&
              props.timeEntriesEnabled === true && (
                <Tooltip title="Time Entries" placement="right">
                  <ListItem
                    button
                    key="Time Entries Employee"
                    style={{
                      padding: "10px 0px",
                      backgroundColor:
                        props.currentTab === "timeEntriesEmployee"
                          ? "#2f3340"
                          : navBackgroundColor,
                    }}
                    onClick={() => props.setCurrentTab("timeEntriesEmployee")}
                  >
                    <ListItemIcon
                      className={classes.listItemIcon}
                      style={{ justifyContent: "center" }}
                    >
                      <PunchClockIcon />
                    </ListItemIcon>
                  </ListItem>
                </Tooltip>
              )}
            {props.permissionRole === "company_employee" && (
              <Tooltip title="User Profile" placement="right">
                <ListItem
                  button
                  key="User Profile"
                  style={{
                    padding: "10px 0px",
                    backgroundColor:
                      props.currentTab === "userProfileEmployee"
                        ? "#2f3340"
                        : navBackgroundColor,
                  }}
                  onClick={() => props.setCurrentTab("userProfileEmployee")}
                >
                  <ListItemIcon
                    className={classes.listItemIcon}
                    style={{ justifyContent: "center" }}
                  >
                    <PersonIcon />
                  </ListItemIcon>
                </ListItem>
              </Tooltip>
            )}
            {props.trainingEnabled === true && (
              <Tooltip title="My Training" placement="right">
                <ListItem
                  button
                  key="Training"
                  style={{
                    padding: "10px 0px",
                    backgroundColor:
                      props.currentTab === "takeTrainingEmployee"
                        ? "#2f3340"
                        : navBackgroundColor,
                  }}
                  onClick={() => props.setCurrentTab("takeTrainingEmployee")}
                >
                  <ListItemIcon
                    className={classes.listItemIcon}
                    style={{ justifyContent: "center" }}
                  >
                    <LocalLibraryIcon />
                  </ListItemIcon>
                </ListItem>
              </Tooltip>
            )}
            {props.permissionRole !== "company_employee" &&
              props.trainingEnabled === true && (
                <Tooltip title="Training Overview" placement="right">
                  <ListItem
                    button
                    key="Training Overview"
                    style={{
                      padding: "10px 0px",
                      backgroundColor:
                        props.currentTab === "trainingOverview"
                          ? "#2f3340"
                          : navBackgroundColor,
                    }}
                    onClick={() => props.setCurrentTab("trainingOverview")}
                  >
                    <ListItemIcon
                      className={classes.listItemIcon}
                      style={{ justifyContent: "center" }}
                    >
                      <SchoolIcon />
                    </ListItemIcon>
                  </ListItem>
                </Tooltip>
              )}
            {(props.permissionRole === "rightwork_internal" ||
              props.permissionRole === "company_admin") &&
              props.trainingEnabled === true && (
                <Tooltip title="Create Training Content" placement="right">
                  <ListItem
                    button
                    key="Create Training Content"
                    style={{
                      padding: "10px 0px",
                      backgroundColor:
                        props.currentTab === "createTrainingContent"
                          ? "#2f3340"
                          : navBackgroundColor,
                    }}
                    onClick={() => props.setCurrentTab("createTrainingContent")}
                  >
                    <ListItemIcon
                      className={classes.listItemIcon}
                      style={{ justifyContent: "center" }}
                    >
                      <MenuBookIcon />
                    </ListItemIcon>
                  </ListItem>
                </Tooltip>
              )}
            {props.chatEnabled === true && (
              <Tooltip title="Chat" placement="right">
                <ListItem
                  button
                  key="Chat"
                  style={{
                    padding: "10px 0px",
                    backgroundColor:
                      props.currentTab === "chat"
                        ? "#2f3340"
                        : navBackgroundColor,
                  }}
                  onClick={() => props.setCurrentTab("chat")}
                >
                  <ListItemIcon
                    className={classes.listItemIcon}
                    style={{ justifyContent: "center" }}
                  >
                    <ChatIcon />
                  </ListItemIcon>
                </ListItem>
              </Tooltip>
            )}
            {props.permissionRole !== "company_employee" &&
              props.logbookEnabled === true && (
                <Tooltip title="Logbook" placement="right">
                  <ListItem
                    button
                    key="Logbook"
                    style={{
                      padding: "10px 0px",
                      backgroundColor:
                        props.currentTab === "logbook"
                          ? "#2f3340"
                          : navBackgroundColor,
                    }}
                    onClick={() => props.setCurrentTab("logbook")}
                  >
                    <ListItemIcon
                      className={classes.listItemIcon}
                      style={{ justifyContent: "center" }}
                    >
                      <EditNoteIcon />
                    </ListItemIcon>
                  </ListItem>
                </Tooltip>
              )}
          </List>
        </div>
      </Drawer>
    </div>
  );
}

/*
   <ListItem button key="Stores">
              <ListItemIcon className={classes.listItemIcon}>
                <StoreIcon />
              </ListItemIcon>
              <ListItemText primary="Stores" className={classes.ListItemText} />
            </ListItem>

            <ListItem button key="Analytics">
              <ListItemIcon className={classes.listItemIcon}>
                <TimelineIcon />
              </ListItemIcon>
              <ListItemText
                primary="Analytics"
                className={classes.ListItemText}
              />
            </ListItem>
            <ListItem button key="Settings">
              <ListItemIcon className={classes.listItemIcon}>
                <InputIcon />
              </ListItemIcon>
              <ListItemText
                primary="Settings"
                className={classes.ListItemText}
              />
            </ListItem>
*/
