import React from 'react'
import { get_t_str } from '../../../../helpers/helpers'

function TaskNameCard(props) {
  return (
    <div className={`p-3 border border-slate-200 rounded-lg ${props.isAssigned ? "bg-slate-100" : "bg-white"}`}>
      <div className="flex justify-between">
        <p className={`text-sm ${props.isAssigned ? "text-slate-400" : "text-slate-900"}`}>{props.task.name}</p>
        <div className="flex items-center space-x-1">
          <p
            className={`text-xs ${props.isAssigned ? "text-slate-400" : "text-slate-500"}`}
          >
            {get_t_str(props.task.start_time)}
          </p>
          <p className={`text-xs ${props.isAssigned ? "text-slate-400" : "text-slate-500"}`}> - </p>
          <p
            className={`text-xs ${props.isAssigned ? "text-slate-400" : "text-slate-500"}`}
          >
            {get_t_str(props.task.end_time)}
          </p>
        </div>
      </div>
      <div className="mt-2">
        <p className={`text-xxs ${props.isAssigned ? "text-slate-400" : "text-slate-900"}`}>
          {props.task.description}
        </p>
      </div>
    </div>
  )
}

export default TaskNameCard
