import React from "react";
import { get_t_str } from "../../../../helpers/helpers";
import TaskNameCard from "./TaskNameCard";

function ScheduledNameCard2(props) {
  const employee = props.employee;
  const selectedDaypart = props.selectedDaypart || {
    start_time: 0,
    end_time: 0,
  };

  const lineStartPercentage =
    Math.max(
      0,
      (employee.shift_start * 60 - selectedDaypart.start_time * 60) /
        ((selectedDaypart.end_time - selectedDaypart.start_time) * 60)
    ) * 100;

  let lineWidthPercentage =
    ((Math.min(employee.shift_end * 60, selectedDaypart.end_time * 60) -
      Math.max(employee.shift_start * 60, selectedDaypart.start_time * 60)) /
      (selectedDaypart.end_time * 60 - selectedDaypart.start_time * 60)) *
    100;

  lineWidthPercentage = lineStartPercentage > 100 ? 0 : lineWidthPercentage;

  const truncateName = (name, maxLength = 20) => {
    return name.length > maxLength ? name.slice(0, maxLength) + '...' : name;
  };

  console.log("ASSIGNED TASK INNER PROPS", props.assignedTasks)
  console.log("ID", employee.id)

  return (
    <div
      key={employee.id}
      className={`border ${props.snapshot.isDraggingOver ? "pb-20 border-emerald-600" : "border-slate-200 border-dashed"} bg-white p-4 rounded-lg overflow-hidden relative w-[300px]`}
    >
      <div className="flex justify-between">
        <p className="text-sm font-medium">{truncateName(employee.name)}</p>
        <div className="flex items-center space-x-1">
          <p
            className={`text-xs ${
              employee.shift_start * 60 > selectedDaypart.start_time * 60
                ? "text-slate-500"
                : "text-slate-500"
            }`}
          >
            {get_t_str(employee.shift_start)}
          </p>
          <p className="text-xs text-slate-500"> - </p>
          <p
            className={`text-xs ${
              employee.shift_end * 60 < selectedDaypart.end_time * 60
                ? "text-slate-500"
                : "text-slate-500"
            }`}
          >
            {get_t_str(employee.shift_end)}
          </p>
        </div>
      </div>
      <div>
        {props.assignedTasks.length > 0 &&
          props.assignedTasks.map((task) => (
            <div key={task.id} className="mt-2">
              <TaskNameCard
                task={task}
              />
            </div>
          ))
        }
        {/* <div className={`w-full h-20 ${props.snapshot.isDraggingOver ? "border-2 border-emerald-600 text-emerald-600" : "border border-slate-300 text-slate-500"} border-dashed rounded-lg flex items-center justify-center mt-2`}>
          <p>Drag task here</p>
        </div> */}
      </div>
      <p
        className="bg-violet-800 absolute bottom-0 h-1"
        style={{
          width: `${lineWidthPercentage}%`,
          left: `${lineStartPercentage}%`,
        }}
      ></p>
    </div>
  );
}

export default ScheduledNameCard2;
